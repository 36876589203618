/*!
 * --- SECTION EVENT ----------------------------
 */
.section-event
  padding-bottom: 85px

  &__hero
    height: 90vh
    max-height: 696px
    min-height: 500px
    display: flex
    flex-direction: column
    justify-content: flex-end
    position: relative
    color: colorLight
    margin-bottom: 60px
    @media pad
      margin-bottom: 30px
    @media phone
      height: 60vh
      min-height: 300px

  &__picture
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: colorDark
    overflow: hidden

    .img
      width: 100%
      height: 100%
      object-fit: cover

  &__header
    position: relative

  &__title
    font-size: 70px
    line-height: (80/70)
    margin-top: 15px
    @media pad
      font-size: 50px
    @media phone
      font-size: 30px

  &__data
    margin-bottom: 60px
    @media pad
      margin-bottom: 30px

  &__quote
    font-family: futura
    font-size: 40px
    font-weight: 900
    line-height: (45/40)
    margin-bottom: 60px
    @media pad
      font-size: 30px
      margin-bottom: 30px
    @media phone
      font-size: 20px

  &__text
    font-size: 20px
    line-height: (28/20)
    padding-left: (100%/12) * 4
    margin-bottom: 60px
    @media pad
      padding-left: 0
    @media phone
      font-size: 18px
      margin-bottom: 30px

    p:not(:last-child)
      margin-bottom: 1.4em


.single-event-data
  display: flex
  justify-content: space-between

  &__item
    font-size: 20px
    line-height: (28/20)
    @media phone
      font-size: 18px
      margin-bottom: 10px

    &:last-child
      text-align: right
      @media pad
        text-align: left
        margin-top: 15px
      @media phone
        margin-top: 10px
