/*!
 --- HELPERS ------------------------------------
*/
.hide
  display: none


.img
  display: block
  max-width: 100%


picture
  img
    width: 100%


.tab
  display: none
  visibility: hidden
  opacity: 0

  &.is-active
    display: block
    visibility: visible
    animation: tab-show 0.25s linear 0.01s 1 forwards


@keyframes tab-show {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}


.iframe-container
  overflow: hidden
  padding-top: 56.25%
  position: relative
  width: 100%

  iframe
    border: 0
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%


.js-fade-in,
.js-fade-up,
.js-hero-animation
  visibility: hidden
  opacity: 0
  @media tablet
    visibility: visible
    opacity: 1


#spotlight .header
  .icon
    @media phone
      width: 30px

  .page
    @media phone
      width: auto
      padding-right: 10px
      padding-left: 0


.is-progress
  position: relative

  &:before
    pseudo()
    width: 100%
    height: 100%
    background-color: rgba(#fff,0.8)
    top: 0
    left: 0
    z-index: 1

  &:after
    pseudo()
    top: 50%
    left: 50%
    width: 2em
    height: 2em
    margin-left: -1em
    margin-top: -1em
    border-style: solid
    border-width: 0.2em
    border-color: colorPrime rgba(#fff,0) colorPrime rgba(#fff,0)
    border-radius: 50%
    animation: progress-rotate 1s ease-in-out infinite forwards
    z-index: 2

@keyframes progress-rotate
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)


/*! --- SPOTLIGHT --------------------- */
#spotlight
  .header
    display: block !important
    padding: 0 15px

    div
      width: (21px + 20) !important
      &:last-child
        padding-right: 0 !important
        width: 21px !important

      &.page
        width: auto !important
        padding: 0 15px 0 0

      &.theme,
      &.player,
      &.autofit
        display: none !important
