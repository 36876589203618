/*!
 * --- SECTION ARTICLE --------------------------
 */
.section-article
  &__top
    margin-bottom: 20px

  &__body
    margin-left: (100%/12) * 2
    @media pad
      margin-left: 0

  &__gallery
    margin-bottom: 52px
    @media phone
      margin-bottom: 26px

  &__video
    margin-bottom: 57px
    @media pad
      margin-bottom: 37px
    @media phone
      margin-bottom: 26px

  &__picture
    max-width: 957px
    margin: 0 auto 57px
    @media pad
      margin-bottom: 37px
    @media phone
      margin-bottom: 26px

    .img
      width: 100%

  &__content
    font-size: 20px
    line-height: 1.4
    @media phone
      font-size: 18px

    p:not(:last-child)
      margin-bottom: 1.4em

  &__footer
    margin-top: 60px
    @media phone
      margin-top: 30px


.v-vlite
  outline: none
