/*!
 * --- BURGER MENU ------------------------------
 */
.burger-menu
  position: fixed
  top: 0
  right: -100%
  overflow-y: auto
  height: 100%
  width: 50%
  background-color: #fff
  z-index: 12
  display: flex
  flex-direction: column
  justify-content: space-between
  padding-top: 115px
  color: colorDark
  transition all 0.5s ease-in-out
  @media note
    padding-top: 40px
  @media pad
    width: 100%
  @media phone
    padding-top: 20px

  &.is-active
    right: 0

  &__close
    position: absolute
    top: 40px
    right: 40px
    @media laptop
      top: 20px
      right: 20px

    .icon
      size(78px,78px)
      fill: colorDark
      transit()
      @media note
        size(40px,40px)
      @media phone
        size(20px,20px)

    &:hover .icon
      fill: colorPrimeLighten

  &__bottom
    text-align: center
    text(14,18)
    color: lighten(colorDark,50%)
    padding: 24px
    @media phone
      font-size: 12px

  &__section
    padding: 60px 40px
    @media tablet
      padding: 30px 20px

    &:not(:last-child)
      border-bottom: 1px solid #b7b7b7


.burger-menu-list
  font-size: 60px
  font-family: nextart
  text-transform: uppercase
  font-weight: bold
  @media note
    font-size: 40px
  @media phone
    font-size: 20px

  &__item
    &.is-desk
      display: none
      @media pad
        display: block

    &:not(:last-child)
      margin-bottom: 0.6em

    &.is-active
      color: colorPrimeLighten

  &__link
    &:hover,
    &.is-active
      color: colorPrimeLighten


.burger-menu-contact
  &__item
    &:not(:last-child)
      margin-bottom: 40px
      @media phone
        margin-bottom: 20px

  &__title
    font-size: 20px
    margin-bottom: 0.6em
    @media phone
      font-size: 14px

  &__txt
    font-size: 40px
    font-family: futura
    font-weight: 500
    @media note
      font-size: 30px
    @media phone
      font-size: 20px

    p:not(:last-child)
      margin-bottom: 0.3em


.burger-socials
  display: flex
  flex-wrap: wrap
  align-items: center
  font-family: futura
  font-weight: 500
  font-size: 40px
  @media note
    font-size: 30px
  @media phone
    font-size: 20px

  &__item
    &:not(:last-child)
      margin-right: 1.6em
      @media note
        margin-right: 1em
