/*!
 * --- BLOCK GO NEXT ----------------------------
 */
.blockGoNext
  display: block
  background-color: #0d0d0d
  color: #fff
  padding: 60px 0 77px
  position: relative
  overflow: hidden
  @media phone
    padding: 30px 0
    text-align: center

  &:after
    pseudo()
    width: 100%
    height: 100%
    max-height: 0
    bottom: 0
    left: 0
    background-color: colorPrime
    transit()

  &__container
    position: relative
    z-index: 1

  &__nav
    margin-left: 30px
    @media phone
      text-align: center
      margin-top: 20px
      margin-left: 0

  &__top
    font-size: 30px
    color: colorPrimeLighten
    margin-bottom: (20/30em)
    transit()
    @media phone
      font-size: 20px

  &__title
    position: relative
    display: flex
    align-items: flex-end
    justify-content: space-between
    @media pad
      align-items: center
    @media phone
      flex-direction: column
      padding-right: 0

  &__name
    font-size: 60px
    font-family: nextart
    font-weight: bold
    @media tablet
      font-size: 50px
    @media pad
      font-size: 40px
    @media phone
      font-size: 25px

    &--trunc
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
      @media pad
        white-space: normal

  &__link
    display: inline-block

    .icon
      size(158px,67px)
      fill: #fff
      transit()
      @media tablet
        size(136px,45px)
      @media phone
        size(79px,33px)

  &:hover
    // background-color: colorPrime
    color: #fff

    &:after
      max-height: 100%

  &:hover &
    &__top
      color: #fff

    &__link
      .icon
        fill: #000
