/*!
 --- FOOTER -------------------------------------
 */
.footer
  padding: 60px 0 50px
  background-color: colorDark
  color: colorLight

  &__container
    max-width: 1080px
    padding: 0 20px
    margin: 0 auto

  &__logo
    margin: 0 auto 44px
    text-align: center

  &__top
    padding-bottom: 60px

  &__line
    display: flex
    flex-wrap: wrap
    align-items: baseline
    @media pad
      flex-direction: column
      text-align: center

    &:not(:last-child)
      margin-bottom: 40px

    &-title
      font-size: 20px
      color: lightness(colorLight,70%)
      width: 100%
      max-width: 29%
      @media pad
        max-width: 100%
        margin-bottom: 10px

    &-content
      width: 100%
      max-width: (100% - 29)
      @media pad
        max-width: 100%

  &__bottom
    text-align center
    font-size 14px
    line-height (18/14)
    color lightness(colorLight,50%)


.footer-contacts
  font-family futura
  font-weight 500
  display flex
  font-size 40px
  @media tablet
    font-size 30px
  @media pad
    justify-content center
  @media phone
    font-size 20px
    flex-direction: column

  &--space-between
    justify-content space-between
    @media tablet
      justify-content flex-start
    @media pad
      justify-content center

  &__item
    white-space: nowrap

    &:not(:last-child)
      margin-right 62px
      @media phone
        margin-right: 0
        margin-bottom: 10px
