/*
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old - 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  laptop = 'all and (max-width: 1366px)'
  tablet = 'all and (max-width: 1199px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/
/*
 --- FONTS --------------------------------------
*/
@font-face {
  font-family: 'Futura PT Book';
  src: local('Futura PT Book'), local('FuturaPT-Book'), url("../fonts/hinted-FuturaPT-Book.woff2") format('woff2'), url("../fonts/hinted-FuturaPT-Book.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura PT Demi';
  src: local('Futura PT Demi'), local('FuturaPT-Demi'), url("../fonts/hinted-FuturaPT-Demi.woff2") format('woff2'), url("../fonts/hinted-FuturaPT-Demi.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Medium'), local('FuturaPT-Medium'), url("../fonts/hinted-FuturaPT-Medium.woff2") format('woff2'), url("../fonts/hinted-FuturaPT-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT Heavy'), local('FuturaPT-Heavy'), url("../fonts/hinted-FuturaPT-Heavy.woff2") format('woff2'), url("../fonts/hinted-FuturaPT-Heavy.woff") format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url("../fonts/hinted-Roboto-Medium.woff2") format('woff2'), url("../fonts/hinted-Roboto-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'NEXT ART';
  src: local('NEXT ART Regular'), local('NEXTART-Regular'), url("../fonts/hinted-NEXTART-Regular.woff2") format('woff2'), url("../fonts/hinted-NEXTART-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NEXT ART';
  src: local('NEXT ART Bold'), local('NEXTART-Bold'), url("../fonts/hinted-NEXTART-Bold.woff2") format('woff2'), url("../fonts/hinted-NEXTART-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
/*
 --- BASE ---------------------------------------
*/
body,
html {
  border: 0;
  width: 100%;
  overflow-x: hidden;
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  font-family: 'Futura PT Book', sans-serif;
  color: #000;
  font-weight: normal;
  background-color: #fff;
  line-height: normal;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  scroll-behavior: smooth;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0,0,0,0.5);
  opacity: 0;
  visibility: hidden;
  transition: all 0.25s linear;
}
#overlay.is-active {
  opacity: 1;
  visibility: visible;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
.container {
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}
@media all and (max-width: 767px) {
  .container {
    padding: 0 15px;
  }
}
.smooth-container {
  scroll-behavior: smooth;
}
/*
 --- FOOTER TO BOTTOM ---------------------------
*/
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 1vh;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}
.wrapper--dark {
  background-color: #000;
  color: #fff;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
 --- UI ELEMENTS --------------------------------
*/
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.25s linear;
}
strong,
b {
  font-weight: bold;
}
img:not([alt]) {
  filter: blur(0);
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  display: block;
  width: auto;
  outline: none;
  line-height: 1;
  padding: 0;
  border: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.25s linear;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
input[type="search"],
input[type="number"] {
  -webkit-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled,
input:disabled,
textarea:disabled,
button.is-disabled,
input.is-disabled,
textarea.is-disabled {
  cursor: not-allowed;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
select.select,
.select select {
  appearance: none;
}
select.select::-ms-expand,
.select select::-ms-expand {
  display: none;
}
ul,
ol {
  padding-left: 1em;
}
/*
 --- GRID ---------------------
*/
.rw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -20px;
}
@media all and (max-width: 767px) {
  .rw {
    margin: 0 -10px;
  }
}
.rw--no-gutters {
  margin: 0;
}
.rw--no-gutters-cl > .cl {
  padding: 0;
}
.rw--a-center {
  align-items: center;
}
.rw--a-start {
  align-items: flex-start;
}
.rw--a-end {
  align-items: flex-end;
}
.cl {
  width: 100%;
  padding: 0 20px;
}
@media all and (max-width: 767px) {
  .cl {
    padding: 0 10px;
  }
}
.cl-1 {
  max-width: 8.333333%;
}
.cl-2 {
  max-width: 16.666666%;
}
.cl-3 {
  max-width: 25%;
}
.cl-4 {
  max-width: 33.333333%;
}
.cl-5 {
  max-width: 41.666666%;
}
.cl-6 {
  max-width: 50%;
}
.cl-7 {
  max-width: 58.333333%;
}
.cl-8 {
  max-width: 66.666666%;
}
.cl-9 {
  max-width: 75%;
}
.cl-10 {
  max-width: 83.333333%;
}
.cl-11 {
  max-width: 91.666666%;
}
.cl-12 {
  max-width: 100%;
}
@media all and (max-width: 1363px) {
  .cl-lp-1 {
    max-width: 8.333333%;
  }
  .cl-lp-2 {
    max-width: 16.666666%;
  }
  .cl-lp-3 {
    max-width: 25%;
  }
  .cl-lp-4 {
    max-width: 33.333333%;
  }
  .cl-lp-5 {
    max-width: 41.666666%;
  }
  .cl-lp-6 {
    max-width: 50%;
  }
  .cl-lp-7 {
    max-width: 58.333333%;
  }
  .cl-lp-8 {
    max-width: 66.666666%;
  }
  .cl-lp-9 {
    max-width: 75%;
  }
  .cl-lp-10 {
    max-width: 83.333333%;
  }
  .cl-lp-11 {
    max-width: 91.666666%;
  }
  .cl-lp-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 1199px) {
  .cl-tb-1 {
    max-width: 8.333333%;
  }
  .cl-tb-2 {
    max-width: 16.666666%;
  }
  .cl-tb-3 {
    max-width: 25%;
  }
  .cl-tb-4 {
    max-width: 33.333333%;
  }
  .cl-tb-5 {
    max-width: 41.666666%;
  }
  .cl-tb-6 {
    max-width: 50%;
  }
  .cl-tb-7 {
    max-width: 58.333333%;
  }
  .cl-tb-8 {
    max-width: 66.666666%;
  }
  .cl-tb-9 {
    max-width: 75%;
  }
  .cl-tb-10 {
    max-width: 83.333333%;
  }
  .cl-tb-11 {
    max-width: 91.666666%;
  }
  .cl-tb-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .cl-pd-1 {
    max-width: 8.333333%;
  }
  .cl-pd-2 {
    max-width: 16.666666%;
  }
  .cl-pd-3 {
    max-width: 25%;
  }
  .cl-pd-4 {
    max-width: 33.333333%;
  }
  .cl-pd-5 {
    max-width: 41.666666%;
  }
  .cl-pd-6 {
    max-width: 50%;
  }
  .cl-pd-7 {
    max-width: 58.333333%;
  }
  .cl-pd-8 {
    max-width: 66.666666%;
  }
  .cl-pd-9 {
    max-width: 75%;
  }
  .cl-pd-10 {
    max-width: 83.333333%;
  }
  .cl-pd-11 {
    max-width: 91.666666%;
  }
  .cl-pd-12 {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .cl-ph-1 {
    max-width: 8.333333%;
  }
  .cl-ph-2 {
    max-width: 16.666666%;
  }
  .cl-ph-3 {
    max-width: 25%;
  }
  .cl-ph-4 {
    max-width: 33.333333%;
  }
  .cl-ph-5 {
    max-width: 41.666666%;
  }
  .cl-ph-6 {
    max-width: 50%;
  }
  .cl-ph-7 {
    max-width: 58.333333%;
  }
  .cl-ph-8 {
    max-width: 66.666666%;
  }
  .cl-ph-9 {
    max-width: 75%;
  }
  .cl-ph-10 {
    max-width: 83.333333%;
  }
  .cl-ph-11 {
    max-width: 91.666666%;
  }
  .cl-ph-12 {
    max-width: 100%;
  }
}
/*
 --- HELPERS ------------------------------------
*/
.hide {
  display: none;
}
.img {
  display: block;
  max-width: 100%;
}
picture img {
  width: 100%;
}
.tab {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.tab.is-active {
  display: block;
  visibility: visible;
  animation: tab-show 0.25s linear 0.01s 1 forwards;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.js-fade-in,
.js-fade-up,
.js-hero-animation {
  visibility: hidden;
  opacity: 0;
}
@media all and (max-width: 1199px) {
  .js-fade-in,
  .js-fade-up,
  .js-hero-animation {
    visibility: visible;
    opacity: 1;
  }
}
@media all and (max-width: 767px) {
  #spotlight .header .icon {
    width: 30px;
  }
}
@media all and (max-width: 767px) {
  #spotlight .header .page {
    width: auto;
    padding-right: 10px;
    padding-left: 0;
  }
}
.is-progress {
  position: relative;
}
.is-progress:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  top: 0;
  left: 0;
  z-index: 1;
}
.is-progress:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  margin-left: -1em;
  margin-top: -1em;
  border-style: solid;
  border-width: 0.2em;
  border-color: #db3434 rgba(255,255,255,0) #db3434 rgba(255,255,255,0);
  border-radius: 50%;
  animation: progress-rotate 1s ease-in-out infinite forwards;
  z-index: 2;
}
/* --- SPOTLIGHT --------------------- */
#spotlight .header {
  display: block !important;
  padding: 0 15px;
}
#spotlight .header div {
  width: 41px !important;
}
#spotlight .header div:last-child {
  padding-right: 0 !important;
  width: 21px !important;
}
#spotlight .header div.page {
  width: auto !important;
  padding: 0 15px 0 0;
}
#spotlight .header div.theme,
#spotlight .header div.player,
#spotlight .header div.autofit {
  display: none !important;
}
@-moz-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes progress-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes progress-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes progress-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes progress-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*
 --- UI -----------------------------------------
*/
.logo {
  display: inline-flex;
  align-items: center;
}
.logo__picture {
  max-width: 200px;
}
@media all and (max-width: 1199px) {
  .logo__picture {
    max-width: 180px;
  }
}
@media all and (max-width: 767px) {
  .logo__picture {
    max-width: 120px;
  }
}
.logo__picture .img {
  width: 100%;
}
.logo__text {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.214285714285714;
  font-family: 'NEXT ART', sans-serif;
  margin-left: 14px;
  text-align: left;
}
@media all and (max-width: 1199px) {
  .logo__text {
    font-size: 12px;
  }
}
@media all and (max-width: 767px) {
  .logo__text {
    margin-left: 10px;
    font-size: 9.5px;
  }
}
.logo--light {
  color: #fff;
}
.logo--dark {
  color: #000;
}
.logo--footer .logo__picture {
  max-width: 260px;
}
@media all and (max-width: 1023px) {
  .logo--footer .logo__picture {
    max-width: 200px;
  }
}
@media all and (max-width: 767px) {
  .logo--footer .logo__picture {
    max-width: 120px;
  }
}
.logo--footer .logo__text {
  font-size: 17px;
  line-height: 1.294117647058824;
  margin-left: 20px;
}
@media all and (max-width: 1023px) {
  .logo--footer .logo__text {
    font-size: 14px;
    line-height: 1.214285714285714;
  }
}
@media all and (max-width: 767px) {
  .logo--footer .logo__text {
    margin-left: 10px;
    font-size: 10px;
  }
}
/* --- LINKS -------------------------- */
.link {
  transition: all 0.25s linear;
}
.link:hover {
  opacity: 0.6;
}
.link-ui {
  transition: all 0.25s linear;
}
.link-ui:hover {
  color: #dc5050;
}
.link-ui:hover .icon {
  fill: #dc5050;
}
.link-color-ui {
  color: #dc5050;
  transition: all 0.25s linear;
}
.link-color-ui:hover {
  color: #d52a2a;
}
.backlink-ui {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #000;
}
@media all and (max-width: 767px) {
  .backlink-ui {
    font-size: 14px;
  }
}
.backlink-ui .icon {
  flex: none;
  fill: #000;
  width: 30px;
  height: 24px;
  margin-right: 14px;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .backlink-ui .icon {
    width: 15px;
    height: 12px;
  }
}
.backlink-ui--light {
  color: #fff;
}
.backlink-ui--light .icon {
  fill: #fff;
}
/* --- PAGE --------------------------- */
.section-page {
  padding: 40px 0 96px;
}
@media all and (max-width: 1023px) {
  .section-page {
    padding: 20px 0 48px;
  }
}
.title-page {
  font-size: 60px;
  font-family: 'NEXT ART', sans-serif;
  line-height: 1.166666666666667;
  text-transform: uppercase;
  margin-bottom: 50px;
}
@media all and (max-width: 1023px) {
  .title-page {
    font-size: 45px;
    margin-bottom: 40px;
  }
}
@media all and (max-width: 767px) {
  .title-page {
    font-size: 30px;
    margin-bottom: 25px;
  }
}
.title-page--single {
  margin-bottom: 0;
}
.section {
  padding: 100px 0;
}
@media all and (max-width: 1199px) {
  .section {
    padding: 50px 0;
  }
}
.section-dark {
  background-color: #000;
  color: #fff;
}
.section-light {
  background-color: #fff;
  color: #000;
}
.section__footer {
  text-align: center;
  padding-top: 20px;
  position: relative;
  z-index: 2;
}
.section__footer .call-button-group {
  padding-top: 20px;
}
.section-header {
  margin-bottom: 55px;
}
@media all and (max-width: 1199px) {
  .section-header {
    margin-bottom: 35px;
  }
}
@media all and (max-width: 767px) {
  .section-header {
    margin-bottom: 25px;
  }
}
.section-title {
  font-size: 40px;
  line-height: 1.25;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
}
@media all and (max-width: 1199px) {
  .section-title {
    font-size: 30px;
  }
}
@media all and (max-width: 1023px) {
  .section-title {
    font-size: 24px;
  }
}
.section-title:not(:last-child) {
  margin-bottom: 25px;
}
@media all and (max-width: 767px) {
  .section-title:not(:last-child) {
    margin-bottom: 15px;
  }
}
.section-title strong {
  font-size: 140px;
  line-height: 1;
}
@media all and (max-width: 1199px) {
  .section-title strong {
    font-size: 70px;
  }
}
@media all and (max-width: 767px) {
  .section-title strong {
    font-size: 35px;
  }
}
.section-subtitle {
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .section-subtitle {
    font-size: 18px;
  }
}
.section-subtitle--half {
  max-width: 50%;
}
@media all and (max-width: 1023px) {
  .section-subtitle--half {
    max-width: 100%;
  }
}
.section-subtitle a {
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
}
/* --- BUTTON ------------------------- */
.btn-border {
  display: inline-flex;
  width: 220px;
  max-width: 100%;
  height: 57px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px 20px 8px;
  font-size: 20px;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  border: 1px solid #fff;
  line-height: 1;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .btn-border {
    width: auto;
    font-size: 18px;
    height: 47px;
  }
}
.btn-border .icon {
  flex: none;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  fill: #fff;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .btn-border .icon {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
}
.btn-border .icon-plus {
  width: 20px;
  height: 20px;
}
.btn-border:hover {
  border-color: #dc5050;
  color: #dc5050;
}
.btn-border:hover .icon {
  fill: #dc5050;
}
.btn-border--dark {
  border-color: #000;
}
.btn-border--dark .icon {
  fill: #000;
}
.btn-border--block {
  width: 100%;
}
.btn-border--inline {
  width: auto;
}
.btn-border--wide {
  width: 100%;
  max-width: 300px;
  border-color: #979797;
}
.btn-border--icon {
  justify-content: space-between;
}
.btn {
  background-color: #000;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding: 5px 10px;
  height: 57px;
}
@media all and (max-width: 767px) {
  .btn {
    height: 40px;
    font-size: 18px;
    padding: 5px 3em;
  }
}
.btn:hover {
  background-color: #dc5050;
}
.btn--300 {
  width: 100%;
  max-width: 300px;
}
@media all and (max-width: 767px) {
  .btn--300 {
    width: auto;
    max-width: none;
  }
}
.btn--block {
  width: 100%;
}
.btn--form {
  height: 66px;
}
@media all and (max-width: 767px) {
  .btn--form {
    height: 46px;
  }
}
.input {
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 0 20px;
  height: 66px;
  border: 1px solid #000;
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .input {
    font-size: 16px;
    height: 46px;
  }
}
.input::placeholder {
  color: #a7a7a7;
}
.fg {
  position: relative;
}
.fg:not(:last-child) {
  margin-bottom: 20px;
}
.fg .error-message {
  position: absolute;
  bottom: 2px;
  right: 5px;
}
.error-message {
  color: #f03e3e;
  font-size: 0.9em;
}
@media all and (max-width: 767px) {
  .error-message {
    font-size: 0.8em;
  }
}
/* --- SLIDER ------------------------- */
.sliderGallery-ui .swiper-container {
  width: 100%;
}
.sliderGallery-ui .slider-controls-group {
  margin-top: 30px;
}
@media all and (max-width: 767px) {
  .sliderGallery-ui .slider-controls-group {
    margin-top: 15px;
  }
}
.sliderGallery-ui .swiper-slide {
  width: 66.45833333333333%;
  opacity: 0.5;
  transition: all 0.25s linear;
  padding: 0 21px;
}
@media all and (max-width: 1023px) {
  .sliderGallery-ui .swiper-slide {
    padding: 0 12px;
  }
}
.sliderGallery-ui .swiper-slide-active {
  opacity: 1;
}
.sliderGallery-ui .swiper-slide .img {
  max-height: 100%;
  margin: 0 auto;
}
.sliderGallery-ui .swiper-slide.is-cover .img {
  object-fit: cover;
  height: 100%;
}
.slider-counter {
  display: flex;
  align-items: center;
  font-size: 40px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  color: #000;
}
@media all and (max-width: 1023px) {
  .slider-counter {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .slider-counter {
    font-size: 16px;
  }
}
.slider-counter .current,
.slider-counter .total {
  display: inline-block;
}
.slider-counter .current {
  margin-right: 8px;
}
.slider-counter .total {
  margin-left: 8px;
}
.slider-controls-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-controls-group .slider-counter {
  margin: 0 20px;
}
.slider-button .icon {
  fill: #000;
  width: 40px;
  height: 78px;
}
@media all and (max-width: 1023px) {
  .slider-button .icon {
    width: 20px;
    height: 39px;
  }
}
.sliderGallery-ui--light .slider-counter {
  color: #fff;
}
.sliderGallery-ui--light .slider-button .icon {
  fill: #fff;
}
.sliderGallery-ui--light .slider-button:hover .icon {
  fill: #dc5050;
}
/* --- VIDEO ------------------------- */
.video-player-ui {
  width: 100%;
  max-width: 957px;
  margin: 0 auto;
}
.video-player-ui .v-vlite .v-bigPlayButton {
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/play.svg");
}
@media all and (max-width: 767px) {
  .video-player-ui .v-vlite .v-bigPlayButton {
    width: 60px;
    height: 60px;
  }
}
.video-player-ui .v-vlite .v-bigPlayButton:hover {
  opacity: 0.6;
}
.video-player-ui .v-vlite .v-bigPlayButton .v-iconBigPlay svg {
  display: none;
}
/* --- COVER PICTURE ----------------- */
.cover-picture {
  max-height: 370px;
  overflow: hidden;
  background-color: #000;
}
@media all and (max-width: 1023px) {
  .cover-picture {
    max-height: 270px;
  }
}
.cover-picture .img {
  width: 100%;
  max-height: 370px;
  object-fit: cover;
}
@media all and (max-width: 1023px) {
  .cover-picture .img {
    max-height: 270px;
  }
}
@media all and (max-width: 767px) {
  .cover-picture .img {
    max-height: 170px;
  }
}
.socials-list {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
.socials-list li:not(:last-child) {
  margin-right: 20px;
}
.accordions__item:not(:last-child) {
  margin-bottom: 46px;
}
@media all and (max-width: 1023px) {
  .accordions__item:not(:last-child) {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .accordions__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.accordions--tight .accordions__item:not(:last-child) {
  margin-bottom: 30px;
}
@media all and (max-width: 1199px) {
  .accordions--tight .accordions__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.accordions-grid {
  margin: 0 -20px;
}
.accordions-grid__item {
  width: 50%;
}
@media all and (max-width: 1023px) {
  .accordions-grid__item {
    width: 100%;
  }
}
.accordions-grid__item .accordion {
  margin: 0 20px;
}
.accordion {
  border: 2px solid #414141;
}
.accordion__header {
  width: 100%;
  min-height: 154px;
  padding: 40px 118px 10px 200px;
  position: relative;
  cursor: pointer;
}
@media all and (max-width: 1199px) {
  .accordion__header {
    min-height: auto;
    padding-top: 16px;
    padding-right: 60px;
    padding-left: 130px;
    padding-bottom: 16px;
  }
}
@media all and (max-width: 1023px) {
  .accordion__header {
    padding-right: 10px;
    padding-left: 90px;
  }
}
@media all and (max-width: 767px) {
  .accordion__header {
    padding-right: 50px;
    padding-left: 40px;
  }
}
.accordion__number {
  font-size: 140px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  color: rgba(213,213,213,0.25);
  position: absolute;
  left: 15px;
  bottom: 0;
  line-height: 0.75;
}
@media all and (max-width: 1199px) {
  .accordion__number {
    font-size: 70px;
  }
}
@media all and (max-width: 1023px) {
  .accordion__number {
    font-size: 50px;
    left: 5px;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media all and (max-width: 767px) {
  .accordion__number {
    font-size: 20px;
  }
}
.accordion__name {
  font-size: 60px;
  text-transform: uppercase;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  transition: all 0.25s linear;
}
@media all and (max-width: 1199px) {
  .accordion__name {
    font-size: 40px;
  }
}
@media all and (max-width: 1023px) {
  .accordion__name {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .accordion__name {
    font-size: 20px;
  }
}
.accordion__arrow {
  position: absolute;
  top: 0;
  right: 40px;
  width: 78px;
  height: 100%;
}
@media all and (max-width: 1199px) {
  .accordion__arrow {
    width: 39px;
    height: 100%;
    right: 20px;
  }
}
@media all and (max-width: 1023px) {
  .accordion__arrow {
    right: 10px;
  }
}
@media all and (max-width: 767px) {
  .accordion__arrow {
    width: 40px;
    height: 100%;
  }
}
.accordion__arrow .icon {
  display: block;
  width: 100%;
  height: 100%;
  fill: #000;
  transition: all 0.25s linear;
}
.accordion__arrow:hover .icon {
  fill: #dc5050;
}
.accordion__header:hover .accordion__name {
  color: #dc5050;
}
.accordion__header:hover .accordion__arrow .icon {
  fill: #dc5050;
}
.accordion__header.is-active .accordion__arrow {
  transform: rotateX(180deg);
}
.accordion__body {
  padding-top: 58px;
  padding-bottom: 46px;
}
@media all and (max-width: 1023px) {
  .accordion__body {
    padding: 10px 0 20px;
  }
}
.accordion__text {
  font-size: 30px;
  padding-left: 16.666666666666668%;
  padding-right: 8.333333333333334%;
}
@media all and (max-width: 1199px) {
  .accordion__text {
    padding-left: 8.333333333333334%;
    font-size: 20px;
  }
}
@media all and (max-width: 1023px) {
  .accordion__text {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (max-width: 767px) {
  .accordion__text {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
}
.accordion__text strong {
  font-family: 'Futura PT Demi', sans-serif;
  line-height: 1.4;
  font-weight: 500;
}
.accordion__text a:hover {
  color: #dc5050;
}
.accordion__text p:not(:last-child) {
  margin-bottom: 1em;
}
.accordion--light {
  color: #fff;
}
.accordion--light .accordion__arrow .icon {
  fill: #fff;
}
.accordion--light .accordion__arrow:hover .icon {
  fill: #dc5050;
}
.accordion--no-number .accordion__header {
  padding: 5px 85px 5px 30px;
  min-height: auto;
  height: 86px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 1199px) {
  .accordion--no-number .accordion__header {
    padding: 10px 70px 10px 20px;
  }
}
@media all and (max-width: 1023px) {
  .accordion--no-number .accordion__header {
    height: auto;
  }
}
@media all and (max-width: 767px) {
  .accordion--no-number .accordion__header {
    padding-right: 50px;
    padding-left: 10px;
  }
}
.accordion--no-number .accordion__body {
  padding-top: 16px;
  padding-bottom: 30px;
}
@media all and (max-width: 767px) {
  .accordion--no-number .accordion__body {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
.accordion--no-number .accordion__name {
  line-height: 1;
  font-size: 26px;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  text-transform: none;
}
@media all and (max-width: 1023px) {
  .accordion--no-number .accordion__name {
    font-size: 20px;
  }
}
@media all and (max-width: 767px) {
  .accordion--no-number .accordion__name {
    line-height: 1.2;
  }
}
.accordion--no-number .accordion__arrow {
  width: 55px;
  height: 100%;
  right: 20px;
}
@media all and (max-width: 1199px) {
  .accordion--no-number .accordion__arrow {
    width: 39px;
    height: 100%;
    right: 20px;
  }
}
@media all and (max-width: 1023px) {
  .accordion--no-number .accordion__arrow {
    right: 10px;
  }
}
@media all and (max-width: 767px) {
  .accordion--no-number .accordion__arrow {
    width: 40px;
    height: 100%;
  }
}
.accordion--no-number .accordion__text {
  font-size: 20px;
  padding-left: 8.333333333333334%;
  padding-right: 8.333333333333334%;
}
@media all and (max-width: 1023px) {
  .accordion--no-number .accordion__text {
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (max-width: 767px) {
  .accordion--no-number .accordion__text {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion-article-list {
  padding-left: 16.666666666666668%;
  padding-right: 8.333333333333334%;
}
@media all and (max-width: 1199px) {
  .accordion-article-list {
    padding-left: 8.333333333333334%;
  }
}
@media all and (max-width: 1023px) {
  .accordion-article-list {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (max-width: 767px) {
  .accordion-article-list {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion-article-list__item:not(:last-child) {
  margin-bottom: 24px;
}
.accordion-article-list__title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 30px;
  font-family: 'Futura PT Demi', sans-serif;
  margin-bottom: 2px;
}
@media all and (max-width: 1023px) {
  .accordion-article-list__title {
    font-size: 20px;
  }
}
@media all and (max-width: 767px) {
  .accordion-article-list__title {
    font-size: 18px;
  }
}
.accordion-article-list__source {
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  color: #b7b7b7;
}
@media all and (max-width: 1023px) {
  .accordion-article-list__source {
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .accordion-article-list__source {
    font-size: 14px;
  }
}
.accordion-troupe__item {
  padding-left: 16.666666666666668%;
  padding-right: 8.333333333333334%;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 1199px) {
  .accordion-troupe__item {
    padding-left: 8.333333333333334%;
  }
}
@media all and (max-width: 1023px) {
  .accordion-troupe__item {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media all and (max-width: 767px) {
  .accordion-troupe__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion-troupe__item:not(:last-child) {
  border-bottom: 1px solid #b7b7b7;
  padding-bottom: 35px;
  margin-bottom: 35px;
}
@media all and (max-width: 1023px) {
  .accordion-troupe__item:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
}
.accordion-troupe__bg {
  font-size: 120px;
  font-family: 'NEXT ART', sans-serif;
  color: #f4f4f4;
  font-weight: bold;
  white-space: nowrap;
  position: absolute;
  left: -10%;
  top: 8px;
}
@media all and (max-width: 1023px) {
  .accordion-troupe__bg {
    font-size: 90px;
    left: -5%;
  }
}
@media all and (max-width: 767px) {
  .accordion-troupe__bg {
    font-size: 60px;
  }
}
.accordion-troupe__text {
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-transform: uppercase;
  position: relative;
}
@media all and (max-width: 1023px) {
  .accordion-troupe__text {
    font-size: 20px;
  }
}
.accordion-troupe__text p:not(:last-child) {
  margin-bottom: 0.3em;
}
.accordion-troupe__text em,
.accordion-troupe__text i {
  text-transform: none;
  font-family: 'Futura PT Book', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}
@media all and (max-width: 1023px) {
  .accordion-troupe__text em,
  .accordion-troupe__text i {
    font-size: 14px;
  }
}
.accordion-event-buy {
  overflow: hidden;
}
.accordion-event-buy__item {
  padding-left: 16.666666666666668%;
  padding-right: 8.333333333333334%;
  align-items: center;
}
@media all and (max-width: 1199px) {
  .accordion-event-buy__item {
    padding-left: 8.333333333333334%;
  }
}
@media all and (max-width: 1023px) {
  .accordion-event-buy__item {
    padding-left: 40px;
    padding-right: 40px;
    align-items: flex-end;
  }
}
@media all and (max-width: 767px) {
  .accordion-event-buy__item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.accordion-event-buy__item:not(:last-child) {
  padding-bottom: 36px;
  margin-bottom: 36px;
  border-bottom: 1px solid #b7b7b7;
}
@media all and (max-width: 1023px) {
  .accordion-event-buy__item:not(:last-child) {
    padding-bottom: 26px;
    margin-bottom: 26px;
  }
}
.accordion-event-buy__place {
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .accordion-event-buy__place {
    font-size: 16px;
    padding-top: 10px;
  }
}
.accordion-event-buy__button {
  text-align: center;
}
@media all and (max-width: 1023px) {
  .accordion-event-buy__button {
    text-align: left;
    padding-top: 20px;
  }
}
.event-date {
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
}
.event-date__number {
  font-family: 'NEXT ART', sans-serif;
  font-size: 80px;
  font-weight: bold;
  line-height: 0.88;
}
@media all and (max-width: 767px) {
  .event-date__number {
    font-size: 50px;
  }
}
.event-date__text {
  text-transform: uppercase;
  padding-left: 14px;
}
.event-date__month {
  font-size: 20px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
}
@media all and (max-width: 767px) {
  .event-date__month {
    font-size: 14px;
  }
}
.event-date__day {
  color: #a6a6a6;
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .event-date__day {
    font-size: 14px;
  }
}
.event-date__label {
  display: inline-flex;
  align-items: center;
  align-self: center;
  font-size: 20px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  color: #dc5050;
  border: 1px solid #dc5050;
  padding: 0 8px;
  height: 31px;
  margin-left: 30px;
}
@media all and (max-width: 1023px) {
  .event-date__label {
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .event-date__label {
    font-size: 14px;
  }
}
.event-date--light .event-date__number {
  color: #fff;
}
.event-date--small .event-date__number {
  font-size: 60px;
}
@media all and (max-width: 767px) {
  .event-date--small .event-date__number {
    font-size: 50px;
  }
}
.event-date.is-soon .event-date__number {
  font-size: 40px;
}
@media all and (max-width: 1023px) {
  .event-date.is-soon .event-date__number {
    font-size: 30px;
  }
}
.accordion-side-by-side {
  padding: 0 40px;
}
@media all and (max-width: 767px) {
  .accordion-side-by-side {
    padding: 0 20px;
  }
}
@media all and (max-width: 1023px) {
  .accordion-side-by-side .side-by-side__item {
    flex-direction: column-reverse;
  }
}
.accordion-side-by-side .side-by-side__item:not(:last-child) {
  margin-bottom: 100px;
}
@media all and (max-width: 1023px) {
  .accordion-side-by-side .side-by-side__item:not(:last-child) {
    margin-bottom: 60px;
  }
}
@media all and (max-width: 1023px) {
  .accordion-side-by-side .side-by-side__item:nth-child(2n) {
    flex-direction: column-reverse;
  }
}
.accordion-side-by-side .side-by-side__item:nth-child(2n):last-child {
  flex-direction: row;
}
@media all and (max-width: 1023px) {
  .accordion-side-by-side.side-by-side--rows .side-by-side__item:nth-child(2n) {
    flex-direction: column-reverse;
  }
}
.picture-overlay {
  position: relative;
}
.picture-overlay__body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #db3434;
  overflow: hidden;
}
.picture-overlay__item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 25%;
  background-color: #dc5050;
  transform: translateX(-100%);
}
.picture-overlay__item:nth-child(2) {
  top: 25%;
}
.picture-overlay__item:nth-child(3) {
  top: 50%;
}
.picture-overlay__item:nth-child(4) {
  top: 75%;
}
/*
 --- HEADER -------------------------------------
 */
.header {
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
@media all and (max-width: 1199px) {
  .header {
    padding: 20px;
  }
}
@media all and (max-width: 767px) {
  .header {
    padding: 15px;
    justify-content: center;
  }
}
.header--dark {
  color: #000;
}
.header--light {
  color: #fff;
}
.header__logo {
  display: block;
  width: 100%;
  max-width: 380px;
}
@media all and (max-width: 767px) {
  .header__left {
    width: 100%;
  }
}
.header__right {
  padding-right: 68px;
  position: relative;
}
@media all and (max-width: 767px) {
  .header__right {
    padding-right: 48px;
  }
}
.main-menu {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}
@media all and (max-width: 1199px) {
  .main-menu {
    font-size: 16px;
  }
}
@media all and (max-width: 1023px) {
  .main-menu {
    background-color: #000;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%;
    overflow-y: auto;
    z-index: 10;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 0 10px 0 #fff;
    transition: all 0.25s linear;
  }
}
.main-menu.is-active {
  right: 0;
}
@media all and (max-width: 1023px) {
  .main-menu__item {
    margin-bottom: 30px;
  }
}
.main-menu__item:not(:first-child) {
  margin-left: 32px;
}
@media all and (max-width: 1199px) {
  .main-menu__item:not(:first-child) {
    margin-left: 16px;
  }
}
@media all and (max-width: 1023px) {
  .main-menu__item:not(:first-child) {
    margin-left: 0;
  }
}
.main-menu__item.is-active {
  color: #dc5050;
}
.main-menu__link[href]:hover {
  opacity: 0.6;
}
.main-menu__link.is-active {
  color: #dc5050;
}
.burger {
  display: block;
  width: 28px;
  height: 12px;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 0;
  z-index: 10;
}
.burger:before,
.burger:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000;
  left: 0;
}
.burger:before {
  top: 0;
}
.burger:after {
  bottom: 0;
}
.burger--light:before,
.burger--light:after {
  background-color: #fff;
}
.burger--dark:before,
.burger--dark:after {
  background-color: #000;
}
/*
 --- FOOTER -------------------------------------
 */
.footer {
  padding: 60px 0 50px;
  background-color: #000;
  color: #fff;
}
.footer__container {
  max-width: 1080px;
  padding: 0 20px;
  margin: 0 auto;
}
.footer__logo {
  margin: 0 auto 44px;
  text-align: center;
}
.footer__top {
  padding-bottom: 60px;
}
.footer__line {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
@media all and (max-width: 1023px) {
  .footer__line {
    flex-direction: column;
    text-align: center;
  }
}
.footer__line:not(:last-child) {
  margin-bottom: 40px;
}
.footer__line-title {
  font-size: 20px;
  color: #b3b3b3;
  width: 100%;
  max-width: 29%;
}
@media all and (max-width: 1023px) {
  .footer__line-title {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.footer__line-content {
  width: 100%;
  max-width: 71%;
}
@media all and (max-width: 1023px) {
  .footer__line-content {
    max-width: 100%;
  }
}
.footer__bottom {
  text-align: center;
  font-size: 14px;
  line-height: 1.285714285714286;
  color: #808080;
}
.footer-contacts {
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  display: flex;
  font-size: 40px;
}
@media all and (max-width: 1199px) {
  .footer-contacts {
    font-size: 30px;
  }
}
@media all and (max-width: 1023px) {
  .footer-contacts {
    justify-content: center;
  }
}
@media all and (max-width: 767px) {
  .footer-contacts {
    font-size: 20px;
    flex-direction: column;
  }
}
.footer-contacts--space-between {
  justify-content: space-between;
}
@media all and (max-width: 1199px) {
  .footer-contacts--space-between {
    justify-content: flex-start;
  }
}
@media all and (max-width: 1023px) {
  .footer-contacts--space-between {
    justify-content: center;
  }
}
.footer-contacts__item {
  white-space: nowrap;
}
.footer-contacts__item:not(:last-child) {
  margin-right: 62px;
}
@media all and (max-width: 767px) {
  .footer-contacts__item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
/*
 * --- SECTION 404 ------------------------------
 */
.section-404 {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 40px 0;
}
@media all and (max-width: 767px) {
  .section-404 {
    padding: 20px 0;
  }
}
.section-404__title {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 60px;
  margin-bottom: 49px;
}
@media all and (max-width: 1199px) {
  .section-404__title {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .section-404__title {
    font-size: 24px;
  }
}
.section-404__title h1 {
  font-size: 2.333333333333333em;
}
.section-404__title p {
  margin-top: -10px;
}
@media all and (max-width: 767px) {
  .section-404__title p {
    margin-top: 0;
  }
}
.section-404__bottom {
  font-size: 18px;
}
/*
 * --- BLOCK GO NEXT ----------------------------
 */
.blockGoNext {
  display: block;
  background-color: #0d0d0d;
  color: #fff;
  padding: 60px 0 77px;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 767px) {
  .blockGoNext {
    padding: 30px 0;
    text-align: center;
  }
}
.blockGoNext:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 0;
  bottom: 0;
  left: 0;
  background-color: #db3434;
  transition: all 0.25s linear;
}
.blockGoNext__container {
  position: relative;
  z-index: 1;
}
.blockGoNext__nav {
  margin-left: 30px;
}
@media all and (max-width: 767px) {
  .blockGoNext__nav {
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }
}
.blockGoNext__top {
  font-size: 30px;
  color: #dc5050;
  margin-bottom: 0.666666666666667em;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .blockGoNext__top {
    font-size: 20px;
  }
}
.blockGoNext__title {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
@media all and (max-width: 1023px) {
  .blockGoNext__title {
    align-items: center;
  }
}
@media all and (max-width: 767px) {
  .blockGoNext__title {
    flex-direction: column;
    padding-right: 0;
  }
}
.blockGoNext__name {
  font-size: 60px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
}
@media all and (max-width: 1199px) {
  .blockGoNext__name {
    font-size: 50px;
  }
}
@media all and (max-width: 1023px) {
  .blockGoNext__name {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .blockGoNext__name {
    font-size: 25px;
  }
}
.blockGoNext__name--trunc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media all and (max-width: 1023px) {
  .blockGoNext__name--trunc {
    white-space: normal;
  }
}
.blockGoNext__link {
  display: inline-block;
}
.blockGoNext__link .icon {
  width: 158px;
  height: 67px;
  fill: #fff;
  transition: all 0.25s linear;
}
@media all and (max-width: 1199px) {
  .blockGoNext__link .icon {
    width: 136px;
    height: 45px;
  }
}
@media all and (max-width: 767px) {
  .blockGoNext__link .icon {
    width: 79px;
    height: 33px;
  }
}
.blockGoNext:hover {
  color: #fff;
}
.blockGoNext:hover:after {
  max-height: 100%;
}
.blockGoNext:hover .blockGoNext__top {
  color: #fff;
}
.blockGoNext:hover .blockGoNext__link .icon {
  fill: #000;
}
/*
 * --- SECTION CONTACTS -------------------------
 */
.blockContacts__item {
  margin-top: 38px;
}
@media all and (max-width: 767px) {
  .blockContacts__item {
    margin-top: 28px;
  }
}
.blockContacts__item:not(:last-child) {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.blockContacts__line:not(:last-child) {
  margin-bottom: 28px;
}
.blockContacts__label {
  font-size: 20px;
}
@media all and (max-width: 1023px) {
  .blockContacts__label {
    font-size: 18px;
  }
}
@media all and (max-width: 767px) {
  .blockContacts__label {
    margin-bottom: 10px;
  }
}
.blockContacts__name {
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 1023px) {
  .blockContacts__name {
    font-size: 16px;
  }
}
.blockContacts__value {
  font-size: 30px;
  text-transform: uppercase;
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
}
@media all and (max-width: 1023px) {
  .blockContacts__value {
    font-size: 20px;
  }
}
.blockContacts__value p:not(:last-child) {
  margin-bottom: 10px;
}
/*
 * --- SECTION ARTICLE --------------------------
 */
.section-article__top {
  margin-bottom: 20px;
}
.section-article__body {
  margin-left: 16.666666666666668%;
}
@media all and (max-width: 1023px) {
  .section-article__body {
    margin-left: 0;
  }
}
.section-article__gallery {
  margin-bottom: 52px;
}
@media all and (max-width: 767px) {
  .section-article__gallery {
    margin-bottom: 26px;
  }
}
.section-article__video {
  margin-bottom: 57px;
}
@media all and (max-width: 1023px) {
  .section-article__video {
    margin-bottom: 37px;
  }
}
@media all and (max-width: 767px) {
  .section-article__video {
    margin-bottom: 26px;
  }
}
.section-article__picture {
  max-width: 957px;
  margin: 0 auto 57px;
}
@media all and (max-width: 1023px) {
  .section-article__picture {
    margin-bottom: 37px;
  }
}
@media all and (max-width: 767px) {
  .section-article__picture {
    margin-bottom: 26px;
  }
}
.section-article__picture .img {
  width: 100%;
}
.section-article__content {
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .section-article__content {
    font-size: 18px;
  }
}
.section-article__content p:not(:last-child) {
  margin-bottom: 1.4em;
}
.section-article__footer {
  margin-top: 60px;
}
@media all and (max-width: 767px) {
  .section-article__footer {
    margin-top: 30px;
  }
}
.v-vlite {
  outline: none;
}
/*
 * --- SECTION ARTICLE LIST ---------------------
 */
.seciton-article-list__picture {
  margin-bottom: 36px;
}
.seciton-article-list__footer {
  margin-top: 35px;
}
.articleList__item {
  display: block;
  transition: all 0.32s linear;
}
.articleList__item:not(:last-child) .articleList__body {
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.articleList__body {
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 767px) {
  .articleList__body {
    flex-wrap: wrap;
  }
}
.articleList__left {
  width: 100%;
  max-width: 60%;
}
@media all and (max-width: 1023px) {
  .articleList__left {
    max-width: 100%;
  }
}
.articleList__right {
  padding-left: 30px;
}
@media all and (max-width: 767px) {
  .articleList__right {
    width: 100%;
    padding-left: 0;
    text-align: right;
    padding-top: 12px;
  }
}
.articleList__date {
  font-size: 24px;
  margin-bottom: 14px;
}
@media all and (max-width: 1023px) {
  .articleList__date {
    font-size: 20px;
  }
}
@media all and (max-width: 767px) {
  .articleList__date {
    font-size: 16px;
  }
}
.articleList__title {
  font-size: 40px;
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  line-height: 1;
}
@media all and (max-width: 1023px) {
  .articleList__title {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .articleList__title {
    font-size: 24px;
  }
}
.articleList__category {
  margin-top: 4px;
  font-size: 20px;
}
@media all and (max-width: 1023px) {
  .articleList__category {
    font-size: 18px;
  }
}
@media all and (max-width: 767px) {
  .articleList__category {
    font-size: 14px;
  }
}
.articleList__link {
  display: inline-block;
  transform: translateX(-20px);
  transition: all 1.5s linear;
}
.articleList__link .icon {
  width: 158px;
  height: 67px;
  fill: #000;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .articleList__link .icon {
    width: 100px;
    height: 9px;
  }
}
.articleList__item:hover {
  background-color: #fff;
  color: #000;
}
.articleList__item:hover .articleList__title {
  color: #db3434;
}
.articleList__item:hover .articleList__link {
  transform: translateX(0);
}
/*
 * --- SECTION TEAM -----------------------------
 */
.section-team {
  padding-bottom: 0;
}
.section-team__items {
  padding: 10px 0 60px;
  background-color: #fff;
  color: #000;
}
@media all and (max-width: 767px) {
  .section-team__items {
    padding: 0 0 20px;
  }
}
.team-list__item {
  padding: 50px 0 0;
}
@media all and (max-width: 767px) {
  .team-list__item {
    padding: 25px 0 0;
  }
}
.team-list__item:not(:last-child) {
  border-bottom: 1px solid #b7b7b7;
}
.team-list__letter {
  font-size: 40px;
  line-height: 1;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #b7b7b7;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .team-list__letter {
    padding-bottom: 20px;
  }
}
.team-list__letter.is-active {
  position: fixed;
  top: 10px;
  z-index: 2;
}
@media all and (max-width: 1023px) {
  .team-list__letter.is-active {
    position: static;
  }
}
.team-list__letter.is-bottom {
  position: absolute;
  bottom: 40px;
  top: auto;
  z-index: 2;
}
@media all and (max-width: 1023px) {
  .team-list__letter.is-bottom {
    position: static;
  }
}
.team-list__persons-item {
  padding-bottom: 40px;
}
.team-person {
  display: block;
}
.team-person__picture {
  height: 307px;
  margin-bottom: 14px;
}
@media all and (max-width: 767px) {
  .team-person__picture {
    height: 207px;
  }
}
.team-person__picture .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team-person__name {
  text-transform: uppercase;
  font-family: 'Futura PT Demi', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.133333333333333;
  transition: all 0.25s linear;
}
@media all and (max-width: 767px) {
  .team-person__name {
    font-size: 18px;
  }
}
.team-person__position {
  margin-top: 4px;
  font-size: 20px;
  color: #b7b7b7;
}
@media all and (max-width: 767px) {
  .team-person__position {
    font-size: 14px;
  }
}
.team-person[href]:hover .team-person__name {
  color: #dc5050;
}
/*
 * --- BLOCK MODAL ------------------------------
 */
.modal__overlay {
  background-color: rgba(0,0,0,0.8);
}
.modal__container {
  color: #000;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
}
.modal-close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 5px;
  top: 5px;
}
@media all and (max-width: 1199px) {
  .modal-close {
    width: 40px;
    height: 40px;
    top: 30px;
    right: 30px;
  }
}
@media all and (max-width: 1023px) {
  .modal-close {
    right: 20px;
    top: 20px;
  }
}
@media all and (max-width: 767px) {
  .modal-close {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
  }
}
.modal-close .icon {
  width: 100%;
  height: 100%;
  fill: #000;
}
.modal-close:hover .icon {
  fill: #dc5050;
  transition: all 0.25s linear;
}
.modal-close--big {
  width: 78px;
  height: 78px;
  right: 40px;
  top: 40px;
}
@media all and (max-width: 1199px) {
  .modal-close--big {
    width: 40px;
    height: 40px;
    top: 30px;
    right: 30px;
  }
}
@media all and (max-width: 1023px) {
  .modal-close--big {
    right: 20px;
    top: 20px;
  }
}
@media all and (max-width: 767px) {
  .modal-close--big {
    width: 30px;
    height: 30px;
    right: 10px;
    top: 10px;
  }
}
.modal-team {
  max-width: 100%;
  max-height: 100%;
  padding: 90px 0;
}
@media all and (max-width: 1199px) {
  .modal-team {
    padding-top: 30px;
  }
}
@media all and (max-width: 1023px) {
  .modal-team {
    padding-top: 20px;
    padding-bottom: 60px;
  }
}
@media all and (max-width: 767px) {
  .modal-team {
    padding: 50px 0 40px;
  }
}
.modal-team__header {
  padding-left: 16.666666666666668%;
  margin-bottom: 45px;
}
@media all and (max-width: 1199px) {
  .modal-team__header {
    padding-left: 0;
    padding-right: 60px;
  }
}
@media all and (max-width: 1023px) {
  .modal-team__header {
    padding-right: 50px;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 767px) {
  .modal-team__header {
    padding-right: 0;
  }
}
.modal-team__position {
  font-size: 20px;
  margin-bottom: 14px;
}
@media all and (max-width: 767px) {
  .modal-team__position {
    font-size: 18px;
  }
}
.modal-team__name {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 80px;
  line-height: 1.125;
}
@media all and (max-width: 1199px) {
  .modal-team__name {
    font-size: 60px;
  }
}
@media all and (max-width: 767px) {
  .modal-team__name {
    font-size: 40px;
  }
}
.modal-team__picture {
  width: 100%;
  max-width: 83.33%;
  max-height: 510px;
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .modal-team__picture {
    width: 100%;
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .modal-team__picture {
    margin-bottom: 30px;
  }
}
.modal-team__picture .img {
  width: 100%;
  height: 100%;
  max-height: 510px;
  object-fit: cover;
}
.modal-team__about {
  padding-left: 16.666666666666668%;
  margin-bottom: 60px;
}
@media all and (max-width: 1199px) {
  .modal-team__about {
    padding-left: 0;
  }
}
.modal-team__quote {
  font-size: 40px;
  line-height: 1.125;
  font-weight: 900;
  font-family: 'Futura PT', sans-serif;
  margin-bottom: 60px;
}
@media all and (max-width: 767px) {
  .modal-team__quote {
    font-size: 24px;
    margin-bottom: 40px;
  }
}
.modal-team__text {
  font-size: 20px;
  line-height: 1.4;
  padding-left: 16.666666666666668%;
}
@media all and (max-width: 1199px) {
  .modal-team__text {
    padding-left: 0;
  }
}
@media all and (max-width: 767px) {
  .modal-team__text {
    font-size: 18px;
  }
}
.modal-team__text p:not(:last-child) {
  margin-bottom: 1.3em;
}
.modal-team__accordions {
  padding-top: 60px;
}
.modal-team-about__item:not(:last-child) {
  margin-bottom: 28px;
}
.modal-team-about__left {
  max-width: 30%;
  text-transform: uppercase;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.4;
  padding-top: 3px;
}
@media all and (max-width: 767px) {
  .modal-team-about__left {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.modal-team-about__right {
  max-width: 70%;
}
@media all and (max-width: 767px) {
  .modal-team-about__right {
    max-width: 100%;
  }
}
.modal-team-about__list {
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .modal-team-about__list {
    font-size: 18px;
  }
}
.modal-video {
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 90%;
  padding: 35px;
  background-color: rgba(255,255,255,0);
}
.modal-video__container {
  width: 920px;
  max-width: 100%;
}
.modal-video .modal-close .icon {
  fill: #fff;
}
.modal-video .modal-close:hover .icon {
  fill: #db3434;
}
/*
 * --- SECTION EVENT ----------------------------
 */
.section-event {
  padding-bottom: 85px;
}
.section-event__hero {
  height: 90vh;
  max-height: 696px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  color: #fff;
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .section-event__hero {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .section-event__hero {
    height: 60vh;
    min-height: 300px;
  }
}
.section-event__picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  overflow: hidden;
}
.section-event__picture .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.section-event__header {
  position: relative;
}
.section-event__title {
  font-size: 70px;
  line-height: 1.142857142857143;
  margin-top: 15px;
}
@media all and (max-width: 1023px) {
  .section-event__title {
    font-size: 50px;
  }
}
@media all and (max-width: 767px) {
  .section-event__title {
    font-size: 30px;
  }
}
.section-event__data {
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .section-event__data {
    margin-bottom: 30px;
  }
}
.section-event__quote {
  font-family: 'Futura PT', sans-serif;
  font-size: 40px;
  font-weight: 900;
  line-height: 1.125;
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .section-event__quote {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 767px) {
  .section-event__quote {
    font-size: 20px;
  }
}
.section-event__text {
  font-size: 20px;
  line-height: 1.4;
  padding-left: 33.333333333333336%;
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .section-event__text {
    padding-left: 0;
  }
}
@media all and (max-width: 767px) {
  .section-event__text {
    font-size: 18px;
    margin-bottom: 30px;
  }
}
.section-event__text p:not(:last-child) {
  margin-bottom: 1.4em;
}
.single-event-data {
  display: flex;
  justify-content: space-between;
}
.single-event-data__item {
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .single-event-data__item {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.single-event-data__item:last-child {
  text-align: right;
}
@media all and (max-width: 1023px) {
  .single-event-data__item:last-child {
    text-align: left;
    margin-top: 15px;
  }
}
@media all and (max-width: 767px) {
  .single-event-data__item:last-child {
    margin-top: 10px;
  }
}
/*
 * --- SECTION EVENT LIST -----------------------
 */
.section-event-list__item:not(:last-child) {
  margin-bottom: 100px;
}
@media all and (max-width: 1023px) {
  .section-event-list__item:not(:last-child) {
    margin-bottom: 60px;
  }
}
.event-list-buy__item {
  display: flex;
}
@media all and (max-width: 767px) {
  .event-list-buy__item {
    flex-direction: column;
  }
}
.event-list-buy__item:not(:last-child) {
  margin-bottom: 40px;
}
@media all and (max-width: 767px) {
  .event-list-buy__item:not(:last-child) {
    margin-bottom: 60px;
  }
}
.event-list-buy__picture {
  max-width: 460px;
  position: relative;
}
@media all and (max-width: 767px) {
  .event-list-buy__picture {
    max-width: 100%;
    height: 200px;
  }
}
.event-list-buy__picture:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 33.333333333333336%;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #000 100%);
}
@media all and (max-width: 767px) {
  .event-list-buy__picture:before {
    left: 0;
    bottom: 0;
    height: 33.333333333333336%;
    width: 100%;
    top: auto;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #000 100%);
  }
}
.event-list-buy__picture .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-list-buy__body {
  flex-grow: 1;
  position: relative;
  left: -60px;
  padding-top: 40px;
  padding-bottom: 20px;
}
@media all and (max-width: 767px) {
  .event-list-buy__body {
    left: auto;
    margin-top: -50px;
    padding-top: 20px;
    padding-bottom: 0;
  }
}
.event-list-buy__header {
  margin-bottom: 15px;
}
@media all and (max-width: 767px) {
  .event-list-buy__header {
    margin-left: 10px;
  }
}
.event-list-buy__title {
  text-transform: uppercase;
  color: #fff;
  font-size: 40px;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}
@media all and (max-width: 1023px) {
  .event-list-buy__title {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .event-list-buy__title {
    font-size: 20px;
  }
}
.event-list-buy__place {
  font-size: 20px;
  line-height: 1.4;
  color: #a6a6a6;
  margin-bottom: 30px;
}
@media all and (max-width: 767px) {
  .event-list-buy__place {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
.event-list-buy__buttons {
  display: flex;
  align-items: center;
}
.event-list-buy__button {
  width: 100%;
  max-width: 272px;
}
.event-list-buy__button:not(:last-child) {
  margin-right: 30px;
}
@media all and (max-width: 767px) {
  .event-list-buy__button:not(:last-child) {
    margin-right: 15px;
  }
}
.event-list-row__item:not(:last-child) {
  margin-bottom: 40px;
}
@media all and (max-width: 1023px) {
  .event-list-row__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.event-list-row__box {
  border: 1px solid #414141;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .event-list-row__box {
    padding: 20px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row__box {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.event-list-row__box[href]:hover {
  background-color: #fff;
}
.event-list-row__title {
  text-transform: uppercase;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 10px;
}
@media all and (max-width: 1023px) {
  .event-list-row__title {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row__title {
    font-size: 20px;
  }
}
.event-list-row__text {
  color: #a6a6a6;
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 1023px) {
  .event-list-row__text {
    font-size: 16px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row__link {
    margin-top: 20px;
    align-self: flex-end;
  }
}
.event-list-row__link .icon {
  width: 158px;
  height: 67px;
  fill: #fff;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .event-list-row__link .icon {
    width: 80px;
    height: 34px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row__link .icon {
    width: 40px;
    height: 17px;
  }
}
.event-list-row__item:hover .event-list-row__title {
  color: #db3434;
}
.event-list-row__item:hover .event-list-row__text {
  color: #000;
}
.event-list-row__item:hover .event-list-row__link .icon {
  fill: #000;
}
.event-list-row__item:hover .event-list-row__link:hover .icon {
  fill: #db3434;
}
/*
 * --- BURGER MENU ------------------------------
 */
.burger-menu {
  position: fixed;
  top: 0;
  right: -100%;
  overflow-y: auto;
  height: 100%;
  width: 50%;
  background-color: #fff;
  z-index: 12;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 115px;
  color: #000;
  transition: all 0.5s ease-in-out;
}
@media all and (max-width: 1760px) {
  .burger-menu {
    padding-top: 40px;
  }
}
@media all and (max-width: 1023px) {
  .burger-menu {
    width: 100%;
  }
}
@media all and (max-width: 767px) {
  .burger-menu {
    padding-top: 20px;
  }
}
.burger-menu.is-active {
  right: 0;
}
.burger-menu__close {
  position: absolute;
  top: 40px;
  right: 40px;
}
@media all and (max-width: 1363px) {
  .burger-menu__close {
    top: 20px;
    right: 20px;
  }
}
.burger-menu__close .icon {
  width: 78px;
  height: 78px;
  fill: #000;
  transition: all 0.25s linear;
}
@media all and (max-width: 1760px) {
  .burger-menu__close .icon {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 767px) {
  .burger-menu__close .icon {
    width: 20px;
    height: 20px;
  }
}
.burger-menu__close:hover .icon {
  fill: #dc5050;
}
.burger-menu__bottom {
  text-align: center;
  font-size: 14px;
  line-height: 1.285714285714286;
  color: #808080;
  padding: 24px;
}
@media all and (max-width: 767px) {
  .burger-menu__bottom {
    font-size: 12px;
  }
}
.burger-menu__section {
  padding: 60px 40px;
}
@media all and (max-width: 1199px) {
  .burger-menu__section {
    padding: 30px 20px;
  }
}
.burger-menu__section:not(:last-child) {
  border-bottom: 1px solid #b7b7b7;
}
.burger-menu-list {
  font-size: 60px;
  font-family: 'NEXT ART', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
}
@media all and (max-width: 1760px) {
  .burger-menu-list {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .burger-menu-list {
    font-size: 20px;
  }
}
.burger-menu-list__item.is-desk {
  display: none;
}
@media all and (max-width: 1023px) {
  .burger-menu-list__item.is-desk {
    display: block;
  }
}
.burger-menu-list__item:not(:last-child) {
  margin-bottom: 0.6em;
}
.burger-menu-list__item.is-active {
  color: #dc5050;
}
.burger-menu-list__link:hover,
.burger-menu-list__link.is-active {
  color: #dc5050;
}
.burger-menu-contact__item:not(:last-child) {
  margin-bottom: 40px;
}
@media all and (max-width: 767px) {
  .burger-menu-contact__item:not(:last-child) {
    margin-bottom: 20px;
  }
}
.burger-menu-contact__title {
  font-size: 20px;
  margin-bottom: 0.6em;
}
@media all and (max-width: 767px) {
  .burger-menu-contact__title {
    font-size: 14px;
  }
}
.burger-menu-contact__txt {
  font-size: 40px;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
}
@media all and (max-width: 1760px) {
  .burger-menu-contact__txt {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .burger-menu-contact__txt {
    font-size: 20px;
  }
}
.burger-menu-contact__txt p:not(:last-child) {
  margin-bottom: 0.3em;
}
.burger-socials {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 40px;
}
@media all and (max-width: 1760px) {
  .burger-socials {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .burger-socials {
    font-size: 20px;
  }
}
.burger-socials__item:not(:last-child) {
  margin-right: 1.6em;
}
@media all and (max-width: 1760px) {
  .burger-socials__item:not(:last-child) {
    margin-right: 1em;
  }
}
/*
 * --- SECTION WORKSHOP -------------------------
 */
.workshop-hero__in {
  height: 540px;
  display: flex;
  align-items: center;
  position: relative;
}
@media all and (max-width: 1023px) {
  .workshop-hero__in {
    height: 420px;
  }
}
.workshop-hero__header {
  width: 100%;
  position: relative;
  z-index: 3;
}
@media all and (max-width: 767px) {
  .workshop-hero__header {
    text-align: center;
  }
}
.workshop-hero__title {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1.2;
}
@media all and (max-width: 1023px) {
  .workshop-hero__title {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .workshop-hero__title {
    font-size: 24px;
  }
}
.workshop-hero__title span {
  display: block;
}
.workshop-hero__title strong {
  font-size: 2.333333333333333em;
  margin-left: 8.333333333333334%;
  line-height: 1;
}
@media all and (max-width: 767px) {
  .workshop-hero__title strong {
    margin-left: 0;
  }
}
.workshop-hero__button {
  margin-top: 40px;
  margin-left: 8.333333333333334%;
}
@media all and (max-width: 767px) {
  .workshop-hero__button {
    margin-left: 0;
  }
}
.workshop-hero__button .btn-border {
  border-width: 2px;
  font-weight: 900;
  width: 100%;
  max-width: 300px;
  justify-content: center;
}
@media all and (max-width: 767px) {
  .workshop-hero__button .btn-border {
    max-width: 260px;
  }
}
.workshop-hero__video {
  position: absolute;
  right: 8.333333333333334%;
  top: 0;
  background-color: #000;
  width: 540px;
  height: 540px;
}
@media all and (max-width: 1199px) {
  .workshop-hero__video {
    right: 0;
  }
}
@media all and (max-width: 1023px) {
  .workshop-hero__video {
    width: 420px;
    height: 420px;
  }
}
@media all and (max-width: 767px) {
  .workshop-hero__video {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    left: -15px;
    right: -15px;
    width: auto;
  }
}
.workshop-hero__video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 100%;
  z-index: 1;
}
.workshop-hero__video-wrapper video {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
}
.workshop-hero__video-desc {
  position: absolute;
  bottom: 7%;
  right: -13%;
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 20px;
  z-index: 2;
}
@media all and (max-width: 1199px) {
  .workshop-hero__video-desc {
    right: 0;
  }
}
@media all and (max-width: 767px) {
  .workshop-hero__video-desc {
    position: relative;
    bottom: auto;
    top: auto;
    text-align: center;
    font-size: 18px;
    padding-bottom: 16px;
  }
}
.call-button-group {
  text-align: left;
}
.call-button-group__title {
  font-size: 30px;
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  line-height: 1;
}
@media all and (max-width: 767px) {
  .call-button-group__title {
    font-size: 26px;
  }
}
.call-button-group__txt {
  font-size: 20px;
  margin-top: 12px;
}
@media all and (max-width: 767px) {
  .call-button-group__txt {
    font-size: 18px;
  }
}
.call-button-group__action {
  margin-top: 30px;
}
@media all and (max-width: 767px) {
  .call-button-group__action {
    margin-top: 20px;
  }
}
.section-workshop-benefits {
  padding-bottom: 60px;
}
@media all and (max-width: 1199px) {
  .section-workshop-benefits {
    padding-bottom: 20px;
  }
}
.benefits-grid {
  justify-content: center;
}
.benefits-grid__item {
  padding-bottom: 40px;
}
.benefit-card {
  text-align: center;
}
.benefit-card__number {
  font-family: 'NEXT ART', sans-serif;
  font-size: 60px;
  font-weight: bold;
}
@media all and (max-width: 1023px) {
  .benefit-card__number {
    font-size: 40px;
  }
}
.benefit-card__txt {
  font-size: 20px;
  line-height: 1.4;
  margin-top: 5px;
}
@media all and (max-width: 1023px) {
  .benefit-card__txt {
    font-size: 16px;
  }
}
.teachers-grid__item {
  padding-bottom: 40px;
}
.teacher-card {
  display: block;
  color: #fff;
}
.teacher-card__picture {
  margin-bottom: 14px;
}
.teacher-card__picture .img {
  width: 100%;
  height: 307px;
  object-fit: cover;
  filter: grayscale(100);
}
@media all and (max-width: 1023px) {
  .teacher-card__picture .img {
    max-width: 220px;
  }
}
@media all and (max-width: 767px) {
  .teacher-card__picture .img {
    height: 200px;
    max-width: 160px;
  }
}
.teacher-card__name {
  font-size: 30px;
  line-height: 1.133333333333333;
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
@media all and (max-width: 767px) {
  .teacher-card__name {
    font-size: 18px;
  }
}
.teacher-card__position {
  font-size: 20px;
  color: #b7b7b7;
  margin-top: 2px;
}
@media all and (max-width: 767px) {
  .teacher-card__position {
    font-size: 14px;
  }
}
.teacher-card[href]:hover .teacher-card__name {
  color: #db3434;
  transition: all 0.25s linear;
}
.side-by-side__item {
  align-items: center;
}
.side-by-side__item:not(:last-child) {
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .side-by-side:not([class*="--rows"]) .side-by-side__item {
    flex-direction: column-reverse;
  }
}
.side-by-side--rows .side-by-side__item:nth-child(2n) {
  flex-direction: row-reverse;
}
.side-by-side--rows .side-by-side__item:nth-child(2n) .pictures-card {
  margin-left: auto;
}
@media all and (max-width: 1023px) {
  .side-by-side-content {
    text-align: center;
    padding-top: 20px;
  }
}
.side-by-side-content__title {
  font-family: 'Futura PT Demi', sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.133333333333333;
}
@media all and (max-width: 767px) {
  .side-by-side-content__title {
    font-size: 24px;
  }
}
.side-by-side-content__txt {
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .side-by-side-content__txt {
    font-size: 18px;
    margin-top: 10px;
  }
}
.pictures-card {
  position: relative;
  max-width: 506px;
}
@media all and (max-width: 1023px) {
  .pictures-card {
    max-width: none;
    display: flex;
    justify-content: center;
    margin: 0 -10px;
  }
}
@media all and (max-width: 767px) {
  .pictures-card {
    margin: 0 -5px;
  }
}
.pictures-card__item {
  max-width: 66.79841897233202%;
  background-color: #fff;
  position: relative;
  z-index: 2;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .pictures-card__item {
    padding: 0 10px;
  }
}
@media all and (max-width: 767px) {
  .pictures-card__item {
    padding: 0 5px;
  }
}
.pictures-card__item:nth-child(2) {
  position: relative;
  margin-top: -35%;
  left: 33%;
  z-index: 1;
}
@media all and (max-width: 1023px) {
  .pictures-card__item:nth-child(2) {
    margin-top: 0;
    left: 0;
  }
}
.pictures-card__item:hover {
  z-index: 5;
}
.pictures-card__box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #222;
  padding: 45px 16px 12px;
  height: 100%;
  position: relative;
  box-shadow: 0 2px 15px 0 rgba(255,255,255,0.1);
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .pictures-card__box {
    padding: 30px 10px 10px;
  }
}
@media all and (max-width: 767px) {
  .pictures-card__box {
    padding: 25px 5px 5px;
  }
}
.pictures-card__box:hover {
  border-color: #dc5050;
}
.pictures-card__resize {
  display: block;
  position: absolute;
  top: 15px;
  right: 17px;
}
@media all and (max-width: 1023px) {
  .pictures-card__resize {
    top: 10px;
    right: 10px;
  }
}
@media all and (max-width: 767px) {
  .pictures-card__resize {
    top: 5px;
    right: 5px;
  }
}
.pictures-card__resize .icon {
  display: block;
  fill: #000;
  width: 12px;
  height: 12px;
  transition: all 0.25s linear;
}
.pictures-card__resize:hover .icon {
  fill: #dc5050;
}
@media all and (max-width: 767px) {
  .pictures-card__picture {
    max-height: 150px;
  }
}
.pictures-card__picture .img {
  width: auto;
  max-height: 192px;
  margin: 0 auto;
}
@media all and (max-width: 767px) {
  .pictures-card__picture .img {
    max-height: 150px;
  }
}
.pictures-card__alt {
  margin-top: 15px;
}
@media all and (max-width: 767px) {
  .pictures-card__alt {
    font-size: 14px;
    margin-top: 5px;
  }
}
.pictures-card--dark .pictures-card__item {
  background-color: #000;
  color: #fff;
}
.pictures-card--dark .pictures-card__resize .icon {
  fill: #fff;
}
.pictures-card--dark .pictures-card__resize:hover .icon {
  fill: #dc5050;
}
.pictures-card--single .pictures-card__item {
  max-width: 338px;
}
.testimonials-grid__item {
  padding-bottom: 40px;
}
.testimonial {
  background-color: #fff;
  color: #000;
}
.testimonial__content {
  font-size: 20px;
  line-height: 1.4;
  padding: 50px 40px 40px 22.5%;
  position: relative;
}
@media all and (max-width: 767px) {
  .testimonial__content {
    padding: 20px;
    font-size: 18px;
  }
}
.testimonial__content:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/quote.png");
  left: 8%;
  top: 32px;
}
@media all and (max-width: 767px) {
  .testimonial__content:before {
    left: 10px;
    top: 10px;
    opacity: 0.3;
  }
}
.testimonial__content button {
  line-height: inherit;
}
.testimonial__txt {
  position: relative;
}
.testimonial__toggle {
  padding: 0;
}
.testimonial__name {
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 30px;
}
@media all and (max-width: 767px) {
  .testimonial__name {
    font-size: 18px;
  }
}
.testimonial__link {
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .testimonial__link {
    font-size: 16px;
  }
}
.testimonial__bottom {
  margin-right: 50px;
  margin-left: 40px;
  padding: 30px 60px 40px 0;
  border-top: 1px solid #f4f4f4;
  position: relative;
}
@media all and (max-width: 767px) {
  .testimonial__bottom {
    margin-right: 20px;
    margin-left: 20px;
    padding: 20px 0;
  }
}
.testimonial__bottom:before {
  content: '';
  display: block;
  position: absolute;
  width: 50px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/quote-reverse.png");
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media all and (max-width: 767px) {
  .testimonial__bottom:before {
    display: none;
  }
}
.testimonial__person {
  display: flex;
  align-items: center;
}
.testimonial__picture {
  flex: none;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 30px;
}
@media all and (max-width: 767px) {
  .testimonial__picture {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}
.testimonial__picture .img {
  object-fit: cover;
  height: 100%;
}
.testimonial__link {
  margin-top: 6px;
}
@media all and (max-width: 1023px) {
  .section-workshop-form {
    padding: 50px 0;
  }
}
.section-workshop-form__wrapper {
  align-items: center;
}
@media all and (max-width: 1023px) {
  .section-workshop-form__wrapper {
    flex-direction: column-reverse;
  }
}
.workshop-form {
  background-color: #ffd5d5;
  padding: 40px;
  position: relative;
}
@media all and (max-width: 767px) {
  .workshop-form {
    padding: 20px;
  }
}
.workshop-form:before {
  content: '';
  display: block;
  position: absolute;
  top: -16px;
  bottom: -16px;
  left: 0;
  right: 0;
  background-color: #ffd5d5;
}
@media all and (max-width: 1023px) {
  .workshop-form:before {
    display: none;
  }
}
.workshop-form__in {
  position: relative;
}
.workshop-form__header {
  margin-bottom: 30px;
}
.workshop-form__title:not(:last-child) {
  margin-bottom: 16px;
}
.workshop-form__agreement {
  text-align: center;
  color: #6c6c6c;
  max-width: 300px;
  margin: 0 auto;
}
.workshop-offer {
  max-width: 407px;
  margin: 0 auto;
}
@media all and (max-width: 1023px) {
  .workshop-offer {
    padding-bottom: 50px;
  }
}
.workshop-offer__top {
  padding-bottom: 25px;
  border-bottom: 1px solid #000;
}
.workshop-offer__title {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 10px;
}
.workshop-offer__price {
  display: flex;
  align-items: center;
}
.workshop-offer__price-value {
  font-family: 'NEXT ART', sans-serif;
  font-weight: 600;
  font-size: 60px;
}
@media all and (max-width: 767px) {
  .workshop-offer__price-value {
    font-size: 42px;
  }
}
.workshop-offer__price-note {
  font-size: 20px;
  line-height: 1.2;
  margin-left: 10px;
}
.workshop-offer__price-note span {
  font-size: 14px;
  color: #9d9d9d;
}
.workshop-offer__middle {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #000;
}
.workshop-offer__steps-title {
  font-family: 'Futura PT', sans-serif;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}
.workshop-offer__steps-list {
  font-size: 20px;
  counter-reset: counter-list;
}
@media all and (max-width: 767px) {
  .workshop-offer__steps-list {
    font-size: 18px;
  }
}
.workshop-offer__steps-list li {
  padding-left: 38px;
  position: relative;
}
.workshop-offer__steps-list li:before {
  counter-increment: counter-list;
  content: counter(counter-list);
  display: block;
  line-height: 24px;
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.workshop-offer__steps-list li:not(:last-child) {
  margin-bottom: 1em;
}
.workshop-offer__bottom {
  padding-top: 30px;
  font-size: 20px;
}
@media all and (max-width: 767px) {
  .workshop-offer__bottom {
    font-size: 18px;
  }
}
.workshop-offer__bottom strong {
  color: #dc5050;
}
/*
 * --- SECTION ABOUT ----------------------------
 */
.section-about {
  padding-top: 0;
}
.about-hero {
  position: relative;
}
.about-hero__body {
  height: 100vh;
  position: relative;
  overflow: hidden;
  max-height: 1080px;
  margin-bottom: 100px;
}
@media all and (max-width: 1199px) {
  .about-hero__body {
    max-height: none;
    height: auto;
    margin-bottom: 0;
  }
}
.about-hero__body:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 4%, #000 84%);
}
.about-hero__picture {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media all and (max-width: 1199px) {
  .about-hero__picture {
    overflow: auto;
    position: relative;
  }
}
.about-hero__picture:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3) 4%, #000 84%);
}
.about-hero__picture .img {
  max-width: none;
  width: 100%;
}
.about-hero__video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
}
@media all and (max-width: 1199px) {
  .about-hero__video {
    height: auto;
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}
.about-hero__center {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 1px;
}
.about-hero__title {
  text-transform: uppercase;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  color: #dc5050;
}
.about-hero__title-value {
  display: inline-block;
  font-size: 40px;
  position: relative;
  transform-origin: 0 50%;
}
@media all and (max-width: 1023px) {
  .about-hero__title-value {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .about-hero__title-value {
    font-size: 18px;
  }
}
.about-content {
  padding-top: 40px;
  padding-bottom: 120px;
}
@media all and (max-width: 1023px) {
  .about-content {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}
.about-content__header {
  text-transform: uppercase;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  margin-bottom: 62px;
}
@media all and (max-width: 767px) {
  .about-content__header {
    margin-bottom: 30px;
  }
}
.about-content__pretitle {
  color: #dc5050;
  font-size: 40px;
  margin-bottom: 40px;
}
@media all and (max-width: 1023px) {
  .about-content__pretitle {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 767px) {
  .about-content__pretitle {
    font-size: 18px;
  }
}
.about-content__title {
  font-size: 60px;
  line-height: 1.166666666666667;
}
@media all and (max-width: 1023px) {
  .about-content__title {
    font-size: 40px;
  }
}
@media all and (max-width: 767px) {
  .about-content__title {
    font-size: 20px;
  }
}
.about-content__letters.animated-lines {
  margin-top: 0;
  padding: 70px 0 72px;
  font-size: 120px;
}
@media all and (max-width: 1199px) {
  .about-content__letters.animated-lines {
    font-size: 70px;
    text-align: center;
  }
}
@media all and (max-width: 1023px) {
  .about-content__letters.animated-lines {
    font-size: 50px;
  }
}
@media all and (max-width: 767px) {
  .about-content__letters.animated-lines {
    padding: 30px 0;
    font-size: 24px;
  }
}
.about-content__txt-picture {
  max-width: 71.36752136752136%;
}
@media all and (max-width: 767px) {
  .about-content__txt-picture {
    max-width: 100%;
  }
}
.about-content__txt-picture .img {
  width: 100%;
}
.about-content__txt-content {
  font-size: 40px;
  max-width: 66.66666666666667%;
}
@media all and (max-width: 1199px) {
  .about-content__txt-content {
    font-size: 30px;
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .about-content__txt-content {
    font-size: 18px;
  }
}
.about-content__txt-content p:not(:last-child) {
  margin-bottom: 1.5em;
}
.about-content__txt-picture + .about-content__txt-content {
  margin-left: 25%;
  margin-top: -1.6em;
  max-width: none;
}
@media all and (max-width: 767px) {
  .about-content__txt-picture + .about-content__txt-content {
    margin-left: 0;
  }
}
.accordion-logo-list {
  padding: 0 40px;
}
@media all and (max-width: 767px) {
  .accordion-logo-list {
    padding: 0 20px;
  }
}
.accordion-logo-list__item {
  display: flex;
  align-items: center;
  font-size: 30px;
  padding: 30px 0;
}
@media all and (max-width: 1023px) {
  .accordion-logo-list__item {
    font-size: 20px;
    padding: 16px 0;
  }
}
.accordion-logo-list__item:not(:last-child) {
  border-bottom: 2px solid #414141;
}
.accordion-logo-list__number {
  width: 100%;
  max-width: 10%;
}
.accordion-logo-list__body {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 90%;
}
.accordion-logo-list__picture {
  width: 100%;
  max-width: 21%;
  min-width: 100px;
}
@media all and (max-width: 767px) {
  .accordion-logo-list__picture {
    min-width: 60px;
  }
}
.accordion-logo-list__picture picture {
  display: block;
}
.accordion-logo-list__picture .img {
  width: auto;
  max-height: 96px;
}
@media all and (max-width: 1023px) {
  .accordion-logo-list__picture .img {
    max-height: 56px;
  }
}
@media all and (max-width: 767px) {
  .accordion-logo-list__picture .img {
    max-height: 42px;
  }
}
@media all and (max-width: 1023px) {
  .about-history-content {
    padding-top: 20px;
  }
}
.about-history-content__title {
  text-transform: uppercase;
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 100px;
  margin-bottom: 20px;
}
@media all and (max-width: 1023px) {
  .about-history-content__title {
    font-size: 24px;
  }
}
.about-history-content__txt {
  font-size: 20px;
}
@media all and (max-width: 1023px) {
  .about-history-content__txt {
    font-size: 18px;
  }
}
.about-history-content__txt p:not(:last-child) {
  margin-bottom: 0.7em;
}
.about-history-content__logo {
  padding-top: 40px;
}
@media all and (max-width: 1023px) {
  .about-history-content__logo {
    padding-top: 20px;
  }
}
.about-history-content__logo .img {
  max-width: 300px;
  max-height: 110px;
}
@media all and (max-width: 1023px) {
  .about-history-content__logo .img {
    max-width: 150px;
    max-height: 50px;
  }
}
.about-history-content__awards {
  font-size: 30px;
}
@media all and (max-width: 1023px) {
  .about-history-content__awards {
    font-size: 18px;
  }
}
.about-history-content__awards[class] {
  padding-top: 30px;
}
@media all and (max-width: 767px) {
  .about-history-content__awards[class] {
    padding-top: 20px;
  }
}
.about-history-content__awards li {
  display: flex;
  align-items: center;
}
.about-history-content__awards li:not(:last-child) {
  margin-bottom: 0.5em;
}
.about-history-content__awards li .icon {
  fill: #f5a1a1;
  width: 28px;
  height: 30px;
  margin-right: 14px;
}
@media all and (max-width: 767px) {
  .about-history-content__awards li .icon {
    width: 18px;
    height: 20px;
  }
}
.accordion-management {
  padding: 0 40px;
}
@media all and (max-width: 767px) {
  .accordion-management {
    padding: 0 20px;
  }
}
.accordion-management__item {
  display: flex;
}
@media all and (max-width: 767px) {
  .accordion-management__item {
    flex-direction: column;
  }
}
.accordion-management__item:not(:last-child) {
  margin-bottom: 58px;
}
@media all and (max-width: 767px) {
  .accordion-management__item:not(:last-child) {
    margin-bottom: 40px;
  }
}
.accordion-management__picture {
  flex: none;
  width: 100%;
  max-width: 48.3271375464684%;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 1023px) {
  .accordion-management__picture {
    max-width: 60%;
  }
}
@media all and (max-width: 767px) {
  .accordion-management__picture {
    max-width: 100%;
    height: 200px;
  }
}
.accordion-management__picture:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to left, #000, rgba(0,0,0,0) 55%);
}
@media all and (max-width: 767px) {
  .accordion-management__picture:before {
    background-image: linear-gradient(to top, #000, rgba(0,0,0,0) 55%);
  }
}
.accordion-management__picture .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.accordion-management__body {
  position: relative;
  left: -70px;
  padding: 60px 0;
}
@media all and (max-width: 1023px) {
  .accordion-management__body {
    left: -35px;
  }
}
@media all and (max-width: 767px) {
  .accordion-management__body {
    left: 0;
    margin-top: -50px;
    padding-top: 20px;
    padding-bottom: 0;
  }
}
.accordion-management__name {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 50px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 16px;
}
@media all and (max-width: 1023px) {
  .accordion-management__name {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .accordion-management__name {
    font-size: 24px;
    margin-bottom: 0;
  }
}
.accordion-management__position {
  font-size: 30px;
}
@media all and (max-width: 1023px) {
  .accordion-management__position {
    font-size: 20px;
  }
}
.accordion-management__bottom {
  padding-top: 40px;
}
@media all and (max-width: 767px) {
  .accordion-management__bottom {
    padding-top: 20px;
  }
}
/*
 * --- SECTION INDEX ----------------------------
 */
.section-index .section__footer {
  padding-top: 60px;
}
@media all and (max-width: 767px) {
  .section-index .section__footer {
    padding-top: 40px;
  }
}
.hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 600px;
  max-height: 1080px;
}
@media all and (max-width: 1199px) {
  .hero {
    height: 80vh;
  }
}
@media all and (max-width: 767px) {
  .hero {
    height: auto;
    min-height: auto;
  }
}
.hero:before {
  content: '';
  display: block;
  position: absolute;
  width: 80%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #000 0%, rgba(0,0,0,0) 100%);
  z-index: 2;
}
.hero:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, #000 0%, rgba(0,0,0,0) 100%);
  z-index: 2;
}
.hero__top {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0;
}
@media all and (max-width: 767px) {
  .hero__top {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.hero__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.hero__picture_desk {
  display: block;
}
@media all and (max-width: 767px) {
  .hero__picture_desk {
    display: none;
  }
}
.hero__picture_mob {
  display: none;
}
@media all and (max-width: 767px) {
  .hero__picture_mob {
    display: block;
  }
}
.hero__picture img {
  width: 100%;
  height: 100%;
  max-width: none;
  object-fit: cover;
}
@media all and (max-width: 767px) {
  .hero__picture img {
    height: auto;
  }
}
.hero__header {
  position: relative;
  z-index: 3;
}
.hero__title {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 140px;
  margin-bottom: 0.392857142857143em;
  line-height: 1;
}
@media all and (max-width: 1199px) {
  .hero__title {
    font-size: 80px;
  }
}
@media all and (max-width: 767px) {
  .hero__title {
    font-size: 70px;
  }
}
.hero__title-prime {
  display: block;
}
.hero__title-second {
  display: block;
  font-size: 0.428571428571429em;
  margin-left: 8.333333333333334%;
  line-height: 1.2;
}
@media all and (max-width: 767px) {
  .hero__title-second {
    margin-left: 0;
  }
}
.hero__txt {
  padding-left: 8.333333333333334%;
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 1199px) {
  .hero__txt {
    font-size: 18px;
  }
}
@media all and (max-width: 767px) {
  .hero__txt {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
  }
}
@media all and (max-width: 767px) {
  .hero__txt br {
    display: none;
  }
}
.hero__bottom {
  position: relative;
  z-index: 3;
  padding-bottom: 6%;
}
@media all and (max-width: 767px) {
  .hero__bottom {
    display: none;
  }
}
.hero__bottom .scroll-down {
  margin-left: 8.333333333333334%;
}
.scroll-down {
  color: #fff;
  opacity: 0.3;
  font-size: 18px;
  line-height: 1.166666666666667;
  display: inline-flex;
  align-items: center;
}
.scroll-down .icon {
  fill: #fff;
  width: 38px;
  height: 53px;
  margin-right: 20px;
}
@media all and (max-width: 767px) {
  .scroll-down .icon {
    width: 18px;
    height: 33px;
    margin-right: 10px;
  }
}
.scroll-down:hover {
  opacity: 0.6;
}
.play-button {
  display: block;
  width: 150px;
  height: 150px;
  position: absolute;
  z-index: 3;
  top: 53.6%;
  left: 52.2%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 1199px) {
  .play-button {
    width: 120px;
    height: 120px;
  }
}
@media all and (max-width: 1023px) {
  .play-button {
    left: auto;
    right: 10%;
  }
}
@media all and (max-width: 767px) {
  .play-button {
    width: 52px;
    height: 52px;
    top: auto;
    top: 35%;
    left: auto;
    right: 20px;
  }
}
.play-button__icon {
  width: 100%;
  height: 100%;
}
.play-button__circle {
  transform-origin: 50% 50%;
  animation: play-button-rotate-in 8s linear infinite forwards;
  animation-play-state: paused;
}
.play-button:hover .play-button__circle {
  animation: play-button-rotate-in 8s linear infinite forwards;
}
.section-index-about__content {
  padding-left: 50%;
  padding-right: 8.333333333333334%;
  margin-left: 30px;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 1199px) {
  .section-index-about__content {
    padding-right: 0;
  }
}
@media all and (max-width: 1023px) {
  .section-index-about__content {
    padding-left: 0;
    margin-left: 0;
  }
}
.section-index-about__title {
  font-family: 'Futura PT Demi', sans-serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 20px;
}
@media all and (max-width: 767px) {
  .section-index-about__title {
    font-size: 30px;
  }
}
.section-index-about__txt {
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 767px) {
  .section-index-about__txt {
    font-size: 18px;
  }
}
.section-index-about__link {
  padding-top: 40px;
}
.section-index-about__link .btn-border {
  width: 272px;
}
.section-index-about__pictures {
  margin-top: -13%;
  padding-right: 20px;
  position: relative;
  z-index: 1;
}
@media all and (max-width: 1023px) {
  .section-index-about__pictures {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-right: 0;
  }
}
.section-index-about__pictures-item {
  max-width: 48%;
}
.section-index-about__pictures-item:nth-child(2n) {
  margin-top: -10%;
  margin-left: 25%;
}
@media all and (max-width: 1023px) {
  .section-index-about__pictures-item:nth-child(2n) {
    margin-top: 0;
    margin-left: 0;
  }
}
.event-list-row-wide__item {
  display: block;
  transition: all 0.32s linear;
}
.event-list-row-wide__item:hover {
  background-color: #fff;
  color: #000;
  transition: all 0.25s linear;
}
.event-list-row-wide__item:hover .event-list-row-wide__title {
  color: #db3434;
}
.event-list-row-wide__item:hover .event-list-row-wide__link {
  transform: translateX(0);
}
.event-list-row-wide__item:hover .event-list-row-wide__link .icon {
  fill: #000;
}
.event-list-row-wide__body {
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
@media all and (max-width: 767px) {
  .event-list-row-wide__body {
    padding: 20px 0;
  }
}
.event-list-row-wide__left {
  width: 100%;
  max-width: 25%;
}
@media all and (max-width: 1199px) {
  .event-list-row-wide__left {
    width: 100%;
    max-width: 33.33%;
  }
}
@media all and (max-width: 767px) {
  .event-list-row-wide__left {
    width: 100%;
    max-width: 100%;
  }
}
.event-list-row-wide__middle {
  width: 100%;
  max-width: 58.33%;
  padding-left: 10px;
}
@media all and (max-width: 1199px) {
  .event-list-row-wide__middle {
    width: 100%;
    max-width: 50%;
  }
}
@media all and (max-width: 767px) {
  .event-list-row-wide__middle {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-top: 20px;
  }
}
.event-list-row-wide__right {
  width: 100%;
  max-width: 16.66%;
  text-align: right;
}
@media all and (max-width: 767px) {
  .event-list-row-wide__right {
    width: 100%;
    max-width: 100%;
  }
}
.event-list-row-wide__time {
  font-size: 20px;
  color: #a6a6a6;
  margin-bottom: 10px;
}
@media all and (max-width: 1023px) {
  .event-list-row-wide__time {
    font-size: 18px;
  }
}
.event-list-row-wide__title {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 10px;
}
@media all and (max-width: 1023px) {
  .event-list-row-wide__title {
    font-size: 30px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row-wide__title {
    font-size: 24px;
  }
}
.event-list-row-wide__info {
  font-size: 20px;
  color: #a6a6a6;
}
@media all and (max-width: 1023px) {
  .event-list-row-wide__info {
    font-size: 18px;
  }
}
.event-list-row-wide__link {
  transform: translateX(-20px);
  transition: all 1.5s linear;
}
.event-list-row-wide__link .icon {
  width: 158px;
  height: 67px;
  fill: #000;
  transition: all 0.25s linear;
}
@media all and (max-width: 1023px) {
  .event-list-row-wide__link .icon {
    width: 80px;
    height: 34px;
  }
}
@media all and (max-width: 767px) {
  .event-list-row-wide__link .icon {
    fill: #fff;
    width: 40px;
    height: 17px;
  }
}
.section-index-program {
  padding-top: 85px;
}
@media all and (max-width: 1023px) {
  .section-index-program {
    padding-top: 50px;
  }
}
.section-index-program .title-page {
  margin-bottom: 65px;
}
@media all and (max-width: 1023px) {
  .section-index-program .title-page {
    margin-bottom: 25px;
  }
}
@media all and (max-width: 1023px) {
  .curriculum {
    padding-top: 30px;
  }
}
.curriculum__item:not(:last-child) {
  margin-bottom: 50px;
}
.curriculum__title {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.curriculum__title:not(:last-child) {
  margin-bottom: 10px;
}
.curriculum__title .icon {
  flex: none;
  fill: #db3434;
  width: 158px;
  height: 67px;
  margin-left: 16px;
  opacity: 0;
  transform: translateX(-20px);
  transition: all 0.5s ease-in-out;
}
@media all and (max-width: 1023px) {
  .curriculum__title .icon {
    width: 136px;
    height: 45px;
  }
}
@media all and (max-width: 767px) {
  .curriculum__title .icon {
    width: 79px;
    height: 33px;
  }
}
.curriculum__title:hover {
  color: #db3434;
}
.curriculum__title:hover .icon {
  opacity: 1;
  transform: translateX(0);
}
.curriculum__txt {
  font-size: 20px;
  line-height: 1.4;
}
@media all and (max-width: 1023px) {
  .curriculum__txt {
    font-size: 18px;
  }
}
.section-pre-lines {
  padding-bottom: 0;
}
.animated-lines {
  font-family: 'NEXT ART', sans-serif;
  font-weight: bold;
  font-size: 180px;
  text-transform: uppercase;
  color: rgba(255,255,255,0.1);
  line-height: 1.03;
  margin-top: -6%;
}
@media all and (max-width: 1363px) {
  .animated-lines {
    font-size: 100px;
  }
}
@media all and (max-width: 1199px) {
  .animated-lines {
    font-size: 90px;
  }
}
@media all and (max-width: 1023px) {
  .animated-lines {
    font-size: 70px;
  }
}
@media all and (max-width: 767px) {
  .animated-lines {
    font-size: 40px;
    margin-top: 6%;
  }
}
.animated-lines .line {
  display: inline-block;
  white-space: nowrap;
  position: relative;
}
.animated-lines + .section {
  padding-top: 0;
  margin-top: -4.5%;
}
@media all and (max-width: 767px) {
  .animated-lines + .section {
    margin-top: 6%;
  }
}
.section-index-events + .animated-lines {
  margin-top: -3.5%;
}
@media all and (max-width: 767px) {
  .section-index-events + .animated-lines {
    margin-top: 6%;
  }
}
@-moz-keyframes play-button-rotate-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes play-button-rotate-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes play-button-rotate-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes play-button-rotate-in {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes play-button-rotate-out {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes play-button-rotate-out {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@-o-keyframes play-button-rotate-out {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes play-button-rotate-out {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
