/*!
 * --- SECTION ARTICLE LIST ---------------------
 */
.seciton-article-list
  &__picture
    margin-bottom: (60px - 24)

  &__footer
    margin-top: 35px


.articleList
  &__item
    display: block
    transit(0.32s)

  &__item:not(:last-child) &__body
    border-bottom: 1px solid rgba(#fff, 0.1)

  &__body
    padding: 24px 0
    display: flex
    align-items: center
    justify-content: space-between
    @media phone
      flex-wrap: wrap

  &__left
    width: 100%
    max-width: 60%
    @media pad
      max-width: 100%

  &__right
    padding-left: 30px
    @media phone
      width: 100%
      padding-left: 0
      text-align: right
      padding-top: 12px

  &__date
    font-size: 24px
    margin-bottom: 14px
    @media pad
      font-size: 20px
    @media phone
      font-size: 16px

  &__title
    font-size: 40px
    font-family: futuraDemi
    font-weight: 500
    line-height: 1
    @media pad
      font-size: 30px
    @media phone
      font-size: 24px

  &__category
    margin-top: 4px
    font-size: 20px
    @media pad
      font-size: 18px
    @media phone
      font-size: 14px

  &__link
    display: inline-block
    transform: translateX(-20px)
    transition: all 1.5s linear

    .icon
      size(158px,67px)
      fill: colorDark
      transit()
      @media pad
        size(100px, 9px)

  &__item:hover
    background-color: #fff
    color: colorDark

  &__item:hover &
    &__title
      color: colorPrime
    &__link
      transform: translateX(0)
