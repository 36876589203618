/*!
 * --- BLOCK MODAL ------------------------------
 */
.modal
  &__overlay
    background-color: rgba(#000, 0.8)

  &__container
    color: colorDark
    position: relative
    width: 100%
    height: 100%
    max-height: 300px
    max-width: 300px

  &-close
    size(24px,24px)
    position: absolute
    right: 5px
    top: 5px
    @media tablet
      size(40px,40px)
      top: 30px
      right: 30px
    @media pad
      right: 20px
      top: 20px
    @media phone
      size(30px,30px)
      right: 10px
      top: 10px

    .icon
      size(100%,100%)
      fill: colorDark

    &:hover .icon
      fill: colorPrimeLighten
      transit()

    &--big
      size(78px,78px)
      right: 40px
      top: 40px
      @media tablet
        size(40px,40px)
        top: 30px
        right: 30px
      @media pad
        right: 20px
        top: 20px
      @media phone
        size(30px,30px)
        right: 10px
        top: 10px


.modal-team
  max-width: 100%
  max-height: 100%
  padding: 90px 0
  @media tablet
    padding-top: 30px
  @media pad
    padding-top: 20px
    padding-bottom: 60px
  @media phone
    padding: 50px 0 40px

  &__header
    padding-left: (100%/12) * 2
    margin-bottom: 45px
    @media tablet
      padding-left: 0
      padding-right: 60px
    @media pad
      padding-right: 50px
      margin-bottom: 20px
    @media phone
      padding-right: 0

  &__position
    font-size: 20px
    margin-bottom: 14px
    @media phone
      font-size: 18px

  &__name
    font-family: nextart
    font-weight: bold
    font-size: 80px
    line-height: (90/80)
    @media tablet
      font-size: 60px
    @media phone
      font-size: 40px

  &__picture
    col(10,12)
    max-height: 510px
    margin-bottom: 60px
    @media pad
      col(1,1)
    @media phone
      margin-bottom: 30px

    .img
      width: 100%
      height: 100%
      max-height: 510px
      object-fit: cover

  &__about
    padding-left: (100%/12) * 2
    margin-bottom: 60px
    @media tablet
      padding-left: 0

  &__quote
    font-size: 40px
    line-height: (45/40)
    font-weight: 900
    font-family: futura
    margin-bottom: 60px
    @media phone
      font-size: 24px
      margin-bottom: 40px

  &__text
    font-size: 20px
    line-height: (28/20)
    padding-left: (100%/12) * 2
    @media tablet
      padding-left: 0
    @media phone
      font-size: 18px

    p:not(:last-child)
      margin-bottom: 1.3em

  &__accordions
    padding-top: 60px


.modal-team-about

  &__item
    &:not(:last-child)
      margin-bottom: 28px

  &__left
    cl(3,10)
    text-transform: uppercase
    font-family: futura
    font-weight: 500
    font-size: 18px
    line-height: (28/20)
    padding-top: 3px
    @media phone
      cl(1,1)
      margin-bottom: 10px

  &__right
    cl(7,10)
    @media phone
      cl(1,1)

  &__list
    font-size: 20px
    line-height: (28/20)
    @media phone
      font-size: 18px


.modal-video
  width: auto
  max-width: 90%
  height: auto
  max-height: 90%
  padding: 35px
  background-color: rgba(255,255,255,0)

  &__container
    width: 920px
    max-width: 100%

  .modal-close
    .icon
      fill: #fff

    &:hover .icon
      fill: colorPrime
