/*!
 * --- SECTION WORKSHOP -------------------------
 */

/* --- HERO --------------------------- */
.workshop-hero
  &__in
    height: 540px
    display: flex
    align-items: center
    position: relative
    @media pad
      height: 420px

  &__header
    width: 100%
    position: relative
    z-index: 3
    @media phone
      text-align: center

  &__title
    font-family: nextart
    font-weight: bold
    font-size: 60px
    text-transform: uppercase
    line-height: 1.2
    @media pad
      font-size: 40px
    @media phone
      font-size: 24px

    span
      display: block

    strong
      font-size: (140 / 60em)
      margin-left: (100%/12) * 1
      line-height: 1
      @media phone
        margin-left: 0

  &__button
    margin-top: 40px
    margin-left: (100%/12) * 1
    @media phone
      margin-left: 0

    .btn-border
      border-width: 2px
      font-weight: 900
      width: 100%
      max-width: 300px
      justify-content: center
      @media phone
        max-width: 260px

  &__video
    position: absolute
    right: (100%/12) * 1
    top: 0
    background-color: #000
    width: 540px
    height: 540px
    @media tablet
      right: 0
    @media pad
      width: 420px
      height: 420px
    @media phone
      width: 100%
      display: flex
      flex-direction: column
      justify-content: flex-end
      left: -15px
      right: -15px
      width: auto

  &__video-wrapper
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    border-radius: 100%
    z-index: 1

    // &:before
    //   pseudo()
    //   top: 0
    //   left: 0
    //   width: 100%
    //   height: 100%
    //   bg(center,cover)
    //   background-image: url('../images/video-circle-overlay.svg')
    //   z-index: 2
    //   @media phone
    //     background-image: none
    //     background-color: rgba(#000,0.6)

    video
      position: absolute
      top: 50%
      left: 50%
      height: 100%
      width: auto
      transform: translate(-50%,-50%)

  &__video-desc
    position: absolute
    bottom: 7%
    right: -13%
    font-family: futura
    font-weight: 500
    font-size: 20px
    z-index: 2
    @media tablet
      right: 0
    @media phone
      position: relative
      bottom: auto
      top: auto
      text-align: center
      font-size: 18px
      padding-bottom: 16px


/* --- WAYS --------------------------- */
.call-button-group
  text-align: left

  &__title
    font-size: 30px
    font-family: futuraDemi
    font-weight: 500
    line-height: 1
    @media phone
      font-size: 26px

  &__txt
    font-size: 20px
    margin-top: 12px
    @media phone
      font-size: 18px

  &__action
    margin-top: 30px
    @media phone
      margin-top: 20px


/* --- BENEFITS ----------------------- */
.section-workshop-benefits
  padding-bottom: 100px - 40
  @media tablet
    padding-bottom: 60px - 40


.benefits-grid
  justify-content: center

  &__item
    padding-bottom: 40px


.benefit-card
  text-align: center

  &__number
    font-family: nextart
    font-size: 60px
    font-weight: bold
    @media pad
      font-size: 40px

  &__txt
    text(20,28)
    margin-top: 5px
    @media pad
      font-size: 16px


/* --- TEACHERS ----------------------- */
.teachers-grid

  &__item
    padding-bottom: 40px


.teacher-card
  display: block
  color: #fff

  &__picture
    margin-bottom: 14px

    .img
      width: 100%
      height: 307px
      object-fit: cover
      filter: grayscale(100)
      @media pad
        max-width: 220px
      @media phone
        height: 200px
        max-width: 160px

  &__name
    text(30,34)
    font-family: futuraDemi
    font-weight: 500
    text-transform: uppercase
    @media phone
      font-size: 18px

  &__position
    font-size: 20px
    color: #b7b7b7
    margin-top: 2px
    @media phone
      font-size: 14px

  &[href]:hover &__name
    color: colorPrime
    transit()


/* --- SIDE BY SIDE ------------------- */
.side-by-side
  &__item
    align-items: center

    &:not(:last-child)
      margin-bottom: 60px

  &:not([class*="--rows"]) &__item
    @media pad
      flex-direction: column-reverse

  &--rows &__item
    &:nth-child(2n)
      flex-direction: row-reverse

      .pictures-card
        margin-left: auto


.side-by-side-content
  @media pad
    text-align: center
    padding-top: 20px

  &__title
    font-family: futuraDemi
    font-weight: 500
    text(30,34)
    @media phone
      font-size: 24px

  &__txt
    margin-top: 20px
    text(20,28)
    @media phone
      font-size: 18px
      margin-top: 10px


.pictures-card
  position: relative
  max-width: 506px
  @media pad
    max-width: none
    display: flex
    justify-content: center
    margin: 0 -10px
  @media phone
    margin: 0 -5px

  &__item
    max-width: ((338 * 100%) / 506)
    background-color: #fff
    position: relative
    z-index: 2
    transit()
    @media pad
      padding: 0 10px
    @media phone
      padding: 0 5px

    &:nth-child(2)
      position: relative
      margin-top: -35%
      // margin-left: 165px
      // transform: translateX(50%)
      left: 33%
      z-index: 1
      @media pad
        margin-top: 0
        // margin-left: 0
        // transform: translateX(0)
        left: 0

    &:hover
      z-index: 5

  &__box
    display: flex
    flex-direction: column
    justify-content: space-between
    border: 1px solid #222222
    padding: 45px 16px 12px
    height: 100%
    position: relative
    box-shadow: 0 2px 15px 0 rgba(255, 255, 255, 0.1)
    transit()
    @media pad
      padding: 30px 10px 10px
    @media phone
      padding: 25px 5px 5px

    &:hover
      border-color: colorPrimeLighten

  &__resize
    display: block
    position: absolute
    top: 15px
    right: 17px
    @media pad
      top: 10px
      right: 10px
    @media phone
      top: 5px
      right: 5px

    .icon
      display: block
      fill: colorDark
      size(12px,12px)
      transit()

    &:hover .icon
      fill: colorPrimeLighten

  &__picture
    @media phone
      max-height: 150px

    .img
      width: auto
      max-height: 192px
      margin: 0 auto
      @media phone
        max-height: 150px

  &__alt
    margin-top: 15px
    @media phone
      font-size: 14px
      margin-top: 5px

  &--dark &
    &__item
      background-color: colorDark
      color: #fff

    &__resize
      .icon
        fill: #fff

      &:hover .icon
        fill: colorPrimeLighten

  &--single &
    &__item
      max-width: 338px


/* --- TESTIMONIALS ------------------- */
.testimonials-grid
  &__item
    padding-bottom: 40px


.testimonial
  background-color: #fff
  color: colorDark

  &__content
    text(20,28)
    padding: 50px 40px 40px 22.5%
    position: relative
    @media phone
      padding: 20px
      font-size: 18px

    &:before
      pseudo()
      size(50px,40px)
      bg(center,contain)
      background-image: url('../icons/quote.png')
      left: 8%
      top: 32px
      @media phone
        left: 10px
        top: 10px
        opacity: 0.3

    button
      line-height: inherit

  &__txt
    position: relative

  &__toggle
    padding: 0

  &__name
    font-family: futura
    font-weight: 500
    font-size: 30px
    @media phone
      font-size: 18px

  &__link
    font-size: 20px
    @media phone
      font-size: 16px

  &__bottom
    margin-right: 50px
    margin-left: 40px
    padding: 30px 60px 40px 0
    border-top: 1px solid #f4f4f4
    position: relative
    @media phone
      margin-right: 20px
      margin-left: 20px
      padding: 20px 0

    &:before
      pseudo()
      size(50px,40px)
      bg(center,contain)
      background-image: url('../icons/quote-reverse.png')
      right: 0
      top: 50%
      transform: translateY(-50%)
      @media phone
        display: none

  &__person
    display: flex
    align-items: center

  &__picture
    flex: none
    size(100px,100px)
    border-radius: 50%
    margin-right: 30px
    @media phone
      size(50px,50px)
      margin-right: 15px

    .img
      object-fit: cover
      height: 100%

  &__link
    margin-top: 6px


/* --- FORM --------------------------- */
.section-workshop-form
  @media pad
    padding: 50px 0

  &__wrapper
    align-items: center
    @media pad
      flex-direction: column-reverse


.workshop-form
  background-color: #ffd5d5
  padding: 40px
  position: relative
  @media phone
    padding: 20px

  &:before
    pseudo()
    top: -16px
    bottom: -16px
    left: 0
    right: 0
    background-color: #ffd5d5
    @media pad
      display: none

  &__in
    position: relative

  &__header
    margin-bottom: 30px

  &__title
    &:not(:last-child)
      margin-bottom: 16px

  &__agreement
    text-align: center
    color: #6c6c6c
    max-width: 300px
    margin: 0 auto


.workshop-offer
  max-width: 407px
  margin: 0 auto
  @media pad
    padding-bottom: 50px

  &__top
    padding-bottom: 25px
    border-bottom: 1px solid #000

  &__title
    font-size: 20px
    line-height: 1
    margin-bottom: 10px

  &__price
    display: flex
    align-items: center

    &-value
      font-family: nextart
      font-weight: 600
      font-size: 60px
      @media phone
        font-size: 42px

    &-note
      font-size: 20px
      line-height: 1.2
      margin-left: 10px

      span
        font-size: 14px
        color: #9d9d9d

  &__middle
    padding-top: 30px
    padding-bottom: 30px
    border-bottom: 1px solid #000

  &__steps
    &-title
      font-family: futura
      font-weight: 500
      font-size: 20px
      margin-bottom: 20px

    &-list
      font-size: 20px
      counter-reset: counter-list
      @media phone
        font-size: 18px

      li
        padding-left: 38px
        position: relative

        &:before
          counter-increment: counter-list
          content: counter(counter-list)
          display: block
          line-height: 24px
          font-size: 16px
          size(24px,24px)
          border-radius: 50%
          background-color: colorDark
          color: #fff
          text-align: center
          position: absolute
          top: 50%
          left: 0
          transform: translateY(-50%)

        &:not(:last-child)
          margin-bottom: 1em

  &__bottom
    padding-top: 30px
    font-size: 20px
    @media phone
      font-size: 18px

    strong
      color: colorPrimeLighten
