/*!
 --- HEADER -------------------------------------
 */

.header
  padding: 40px
  display: flex
  align-items: center
  justify-content: space-between
  // position: relative
  position: absolute
  top: 0
  left: 0
  width: 100%
  z-index: 10
  @media tablet
    padding: 20px
  @media phone
    padding 15px
    justify-content: center

  &--dark
    color: #000

  &--light
    color: #fff

  &__logo
    display: block
    width: 100%
    max-width: 380px

  &__left
    @media phone
      width: 100%

  &__right
    padding-right: 68px
    position: relative
    @media phone
      padding-right: 48px


.main-menu
  display: flex
  align-items: center
  font-family: roboto
  font-weight: 500
  font-size: 20px
  text-transform: uppercase
  @media tablet
    font-size: 16px
  @media pad
    background-color: #000
    position: fixed
    top: 0
    right: -100%
    height: 100%
    width: 50%
    overflow-y: auto
    z-index: 10
    flex-direction: column
    justify-content: center
    box-shadow 0 0 10px 0 #fff
    transit()

  &.is-active
    right: 0

  &__item
    @media pad
      margin-bottom: 30px

    &:not(:first-child)
      margin-left: 32px
      @media tablet
        margin-left: 16px
      @media pad
        margin-left: 0

    &.is-active
      color: colorPrimeLighten

  &__link
    &[href]:hover
      opacity: 0.6

    &.is-active
      color: colorPrimeLighten


.burger
  display: block
  width: 28px
  height: 12px
  position: absolute
  top: 50%
  margin-top: -6px
  right: 0
  z-index: 10

  &:before,
  &:after
    pseudo()
    width: 100%
    height: 2px
    background-color: colorDark
    left: 0

  &:before
    top: 0

  &:after
    bottom: 0

  &--light
    &:before,
    &:after
      background-color: #fff

  &--dark
    &:before,
    &:after
      background-color: #000
