//- COLORS
colorDark = #000
colorLight = #fff
colorPrime = #db3434
colorPrimeLighten = #dc5050

colorError = #f03e3e

//- GRID
gridPadding = 20px

//- BREAKPOINTS
note = 'all and (max-width: 1760px)'
laptop = 'all and (max-width: 1363px)'
tablet = 'all and (max-width: 1199px)'
pad = 'all and (max-width: 1023px)'
phone = 'all and (max-width: 767px)'

/*!
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old - 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  laptop = 'all and (max-width: 1366px)'
  tablet = 'all and (max-width: 1199px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/

/*!
 --- FONTS --------------------------------------
*/
futura = 'Futura PT', sans-serif
futuraBook = 'Futura PT Book', sans-serif
futuraDemi = 'Futura PT Demi', sans-serif
roboto = 'Roboto', sans-serif
nextart = 'NEXT ART', sans-serif

@font-face
  font-family: 'Futura PT Book'
  src: local('Futura PT Book'), local('FuturaPT-Book'),
    url('../fonts/hinted-FuturaPT-Book.woff2') format('woff2'),
    url('../fonts/hinted-FuturaPT-Book.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Futura PT Demi'
  src: local('Futura PT Demi'), local('FuturaPT-Demi'),
    url('../fonts/hinted-FuturaPT-Demi.woff2') format('woff2'),
    url('../fonts/hinted-FuturaPT-Demi.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'Futura PT'
  src: local('Futura PT Medium'), local('FuturaPT-Medium'),
    url('../fonts/hinted-FuturaPT-Medium.woff2') format('woff2'),
    url('../fonts/hinted-FuturaPT-Medium.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
    font-family: 'Futura PT'
    src: local('Futura PT Heavy'), local('FuturaPT-Heavy'),
        url('../fonts/hinted-FuturaPT-Heavy.woff2') format('woff2'),
        url('../fonts/hinted-FuturaPT-Heavy.woff') format('woff')
    font-weight: 900
    font-style: normal

@font-face
  font-family: 'Roboto'
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/hinted-Roboto-Medium.woff2') format('woff2'),
    url('../fonts/hinted-Roboto-Medium.woff') format('woff')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'NEXT ART'
  src: local('NEXT ART Regular'), local('NEXTART-Regular'),
    url('../fonts/hinted-NEXTART-Regular.woff2') format('woff2'),
    url('../fonts/hinted-NEXTART-Regular.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'NEXT ART'
  src: local('NEXT ART Bold'), local('NEXTART-Bold'),
    url('../fonts/hinted-NEXTART-Bold.woff2') format('woff2'),
    url('../fonts/hinted-NEXTART-Bold.woff') format('woff')
  font-weight: bold
  font-style: normal
