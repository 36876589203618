//- TEXT TRUNCATE
trunc($lines)
  display: box
  display: -ms-box
  display: -webkit-box
  display: -moz-box
  text-overflow: ellipsis
  overflow: hidden
  -webkit-line-clamp: $lines
  box-orient: vertical
  -moz-box-orient: vertical
  -webkit-box-orient: vertical
  -ms-box-orient: vertical

//- TRANSITION
transit($duration = 0.25s)
  transition: all $duration linear

//- PSEUDO ELEMENTS
pseudo()
  content: ''
  display: block
  position: absolute

//- BACKGROUD
bg($position, $size)
  background-repeat: no-repeat
  background-position: $position
  background-size: $size

//- RESET UL
resetul()
  margin: 0
  padding: 0
  list-style-type: none

//- SET SIZE
size($wsize, $hsize)
  width: $wsize
  height: $hsize

text($fontsize, $lineheight)
  font-size: unit($fontsize, 'px')
  line-height: ($lineheight / $fontsize)
