/*!
 --- BASE ---------------------------------------
*/

body, html
  border: 0
  width: 100%
  overflow-x: hidden

html
  font-size: 16px
  scroll-behavior: smooth

body
  font-family: futuraBook
  color: colorDark
  font-weight: normal
  background-color: #fff
  line-height: normal
  overflow: hidden
  display: flex
  min-height: 100vh
  scroll-behavior: smooth

#overlay
  position: fixed
  width: 100%
  height: 100%
  top: 0
  left: 0
  z-index: 10
  background-color: rgba(#000, 0.5)
  opacity: 0
  visibility: hidden
  transit()

  &.is-active
    opacity: 1
    visibility: visible


.browsehappy
  display: block
  margin: 0
  padding: 30px
  text-align: center
  background: #e55454
  color: #fff
  font-size: 18px
  line-height: 1.4

  a,
  a:hover
    color: #fff
    text-decoration: underline

.container
  width: 100%
  max-width: 1200px
  padding: 0 20px
  margin: 0 auto
  @media phone
    padding: 0 15px


.smooth-container
  scroll-behavior: smooth


/*!
 --- FOOTER TO BOTTOM ---------------------------
*/
.wrapper
  flex: 1 1 auto
  width: 100%
  min-height: 1vh
  display: flex
  flex-flow: column nowrap
  position: relative

  &--dark
    background-color: #000
    color: #fff

.main
  flex: 1 1 auto
  min-height: 1vh

.header, .footer
  flex-shrink: 0


/*!
 --- UI ELEMENTS --------------------------------
*/

a
  font-size: inherit
  font-family: inherit
  text-decoration: none
  color: inherit
  transit()


strong, b
  font-weight: bold


img:not([alt])
    filter: blur(0)


button, input, select, textarea
  font-family: inherit
  font-size: inherit
  color: inherit
  display: block
  width: auto
  outline: none
  line-height: 1
  padding: 0
  border: 0
  background-color: rgba(255,255,255,0)
  transit()


button,
button[type="submit"],
input[type="submit"]
  cursor: pointer
  // padding: 0


input[type="search"],
input[type="number"]
  -webkit-appearance: textfield


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0


button, input, textarea
  &:disabled
  &.is-disabled
    cursor: not-allowed


textarea
  width: 100%
  resize: none


fieldset
  border: none
  margin: 0
  padding: 0


select.select,
.select select
    appearance: none

    &::-ms-expand
      display: none


ul, ol
  padding-left: 1em


/*!
 --- GRID ---------------------
*/
.rw
  display: flex
  flex-direction: row
  flex-wrap: wrap
  list-style: none
  margin: 0 (-(gridPadding))
  @media phone
    margin: 0 (-(gridPadding / 2))

  &--no-gutters
    margin: 0

  &--no-gutters-cl
    & > .cl
      padding: 0

  &--a-center
    align-items: center

  &--a-start
    align-items: flex-start

  &--a-end
    align-items: flex-end

.cl
  width: 100%
  padding: 0 gridPadding
  @media phone
    padding: 0 (gridPadding / 2)

cl(num,columns)
  max-width: floor( ((100% / columns)* num), 2 )

col(num, columns)
  width: 100%
  max-width: floor( ((100% / columns)* num), 2 )

grid($columns, $prefix = '')
  for $col in 1..$columns
    .cl{$prefix}-{$col}
      max-width floor( ((100% / $columns) * $col), 6 )

grid(12)

@media laptop
  grid(12, '-lp')

@media tablet
  grid(12, '-tb')

@media pad
  grid(12, '-pd')

@media phone
  grid(12, '-ph')
