/*!
 * --- SECTION INDEX ----------------------------
 */
.section-index
  .section__footer
    padding-top: 60px
    @media phone
      padding-top: 40px


/* --- HERO --------------------------- */
.hero
  height: 100vh
  display: flex
  flex-direction: column
  justify-content: center
  position: relative
  min-height: 600px
  max-height: 1080px
  @media tablet
    height: 80vh
  @media phone
    height: auto
    min-height: auto

  &:before
    pseudo()
    width: 80%
    height: 100%
    top: 0
    left: 0
    background-image: linear-gradient(to right, #000 0%, rgba(#000, 0) 100%)
    z-index: 2

  &:after
    pseudo()
    width: 100%
    height: 100%
    bottom: 0
    left: 0
    background-image: linear-gradient(to top, #000 0%, rgba(#000, 0) 100%)
    z-index: 2

  &__top
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    padding: 50px 0
    @media phone
      padding-top: 0
      padding-bottom: 0

  &__picture
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    z-index: 1

    &_desk
      display: block
      @media phone
        display: none

    &_mob
      display: none
      @media phone
        display: block

    img
      width: 100%
      height: 100%
      max-width: none
      object-fit: cover
      @media phone
        height: auto


  &__header
    position: relative
    z-index: 3

  &__title
    font-family: nextart
    font-weight: bold
    font-size: 140px
    margin-bottom: (55em/140)
    line-height: 1
    @media tablet
      font-size: 80px
    @media phone
      font-size: 70px

    &-prime
      display: block

    &-second
      display: block
      font-size: (60em/140)
      margin-left: (100% / 12)
      line-height: 1.2
      @media phone
        margin-left: 0

  &__txt
    padding-left: (100% / 12)
    text(20,28)
    @media tablet
      font-size: 18px
    @media phone
      col(1,1)
      padding-left: 0

    br
      @media phone
        display: none

  &__bottom
    position: relative
    z-index: 3
    padding-bottom: 6%
    @media phone
      display: none

    .scroll-down
      margin-left: (100% / 12)


.scroll-down
  color: #fff
  opacity: 0.3
  text(18,21)
  display: inline-flex
  align-items: center

  .icon
    fill: #fff
    size(38px,53px)
    margin-right: 20px
    @media phone
      size(18px,33px)
      margin-right: 10px

  &:hover
    opacity: 0.6


.play-button
  display: block
  size(150px,150px)
  position: absolute
  z-index: 3
  top: 53.6%
  left: 52.2%
  display: flex
  align-items: center
  justify-content: center
  @media tablet
    size(120px,120px)
  @media pad
    left: auto
    right: 10%
  @media phone
    size(52px,52px)
    top: auto
    top: 35%
    left: auto
    right: 20px

  &__icon
    width: 100%
    height: 100%

  &__circle
    transform-origin: 50% 50%
    animation: play-button-rotate-in 8s linear infinite forwards
    animation-play-state: paused

  &:hover &__circle
    animation: play-button-rotate-in 8s linear infinite forwards


@keyframes play-button-rotate-in
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)

@keyframes play-button-rotate-out
  from
    transform: rotate(360deg)
  to
    transform: rotate(0deg)


/* --- INDEX ABOUT -------------------- */
.section-index-about
  &__content
    padding-left: (100% / 12) * 6
    padding-right: (100% / 12)
    margin-left: 30px
    position: relative
    z-index: 2
    @media tablet
      padding-right: 0
    @media pad
      padding-left: 0
      margin-left: 0

  &__title
    font-family: futuraDemi
    font-size: 40px
    font-weight: 500
    line-height: 1
    margin-bottom: 20px
    @media phone
      font-size: 30px

  &__txt
    text(20,28)
    @media phone
      font-size: 18px

  &__link
    padding-top: 40px

    .btn-border
      width: 272px

  &__pictures
    margin-top: -13%
    padding-right: 20px
    position: relative
    z-index: 1
    @media pad
      margin-top: 40px
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      padding-right: 0

    &-item
      max-width: 48%

      &:nth-child(2n)
        margin-top: -10%
        margin-left: (100% / 12) * 3
        @media pad
          margin-top: 0
          margin-left: 0


/* --- INDEX EVENTS ------------------- */
.event-list-row-wide
  &__item
    display: block
    transit(0.32s)

  &__item:hover
    background-color: #fff
    color: colorDark
    transit()

  &__item:hover &__title
    color: colorPrime

  &__item:hover &__link
    transform: translateX(0)
    .icon
      fill: colorDark

  &__body
    padding: 30px 0
    display: flex
    flex-wrap: wrap
    align-items: center
    border-bottom: 1px solid rgba(255, 255, 255, 0.1)
    @media phone
      padding: 20px 0

  &__left
    col(3,12)
    @media tablet
      col(4,12)
    @media phone
      col(1,1)

  &__middle
    col(7,12)
    padding-left: 10px
    @media tablet
      col(6,12)
    @media phone
      col(1,1)
      padding-left: 0
      padding-top: 20px

  &__right
    col(2,12)
    text-align: right
    @media phone
      col(1,1)

  &__time
    font-size: 20px
    color: #a6a6a6
    margin-bottom: 10px
    @media pad
      font-size: 18px

  &__title
    font-family: nextart
    font-weight: bold
    font-size: 40px
    margin-bottom: 10px
    @media pad
      font-size: 30px
    @media phone
      font-size: 24px

  &__info
    font-size: 20px
    color: #a6a6a6
    @media pad
      font-size: 18px

  &__link
    transform: translateX(-20px)
    transition: all 1.5s linear
    .icon
      size(158px,67px)
      fill: colorDark
      transit()
      @media pad
        size(80px,34px)
      @media phone
        fill: #fff
        size(40px,17px)


/* --- INDEX PROGRAM ------------------ */
.section-index-program
  padding-top: 85px
  @media pad
    padding-top: 50px

  .title-page
    margin-bottom: 65px
    @media pad
      margin-bottom: 25px


.curriculum
  @media pad
    padding-top: 30px

  &__item
    &:not(:last-child)
      margin-bottom: 50px

  &__title
    line-height: 1
    display: flex
    align-items: center
    justify-content: space-between

    &:not(:last-child)
      margin-bottom: 10px

    .icon
      flex: none
      fill: colorPrime
      size(158px,67px)
      margin-left: 16px
      opacity: 0
      transform: translateX(-20px)
      transition: all 0.5s ease-in-out
      @media pad
        size(136px,45px)
      @media phone
        size(79px,33px)

    &:hover
      color: colorPrime

      .icon
        opacity: 1
        transform: translateX(0)

  &__txt
    text(20,28)
    @media pad
      font-size: 18px


/* --- LINES -------------------------- */
.section-pre-lines
  padding-bottom: 0


.animated-lines
  font-family: nextart
  font-weight: bold
  font-size: 180px
  text-transform: uppercase
  color: rgba(#fff,0.1)
  line-height: 1.03
  margin-top: -6%
  @media laptop
    font-size: 100px
  @media tablet
    font-size: 90px
  @media pad
    font-size: 70px
  @media phone
    font-size: 40px
    margin-top: 6%

  .line
    display: inline-block
    white-space: nowrap
    position: relative

  & + .section
    padding-top: 0
    margin-top: -4.5%
    @media phone
      margin-top: 6%


.section-index-events
  & + .animated-lines
    margin-top: -3.5%
    @media phone
      margin-top: 6%
