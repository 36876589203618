/*!
 --- UI -----------------------------------------
*/

/* --- LOGO --------------------------- */
.logo
  display: inline-flex
  align-items: center

  &__picture
    max-width: 200px
    @media tablet
      max-width: 180px
    @media phone
      max-width: 120px

    .img
      width: 100%

  &__text
    text-transform: uppercase
    font-size: 14px
    line-height: (17/14)
    font-family: nextart
    margin-left: 14px
    text-align: left
    @media tablet
      font-size: 12px
    @media phone
      margin-left: 10px
      font-size: 9.5px

  &--light
    color: colorLight

  &--dark
    color: colorDark

  &--footer &
    &__picture
      max-width: 260px
      @media pad
        max-width: 200px
      @media phone
        max-width: 120px

    &__text
      font-size: 17px
      line-height: (22/17)
      margin-left: 20px
      @media pad
        font-size: 14px
        line-height: (17/14)
      @media phone
        margin-left: 10px
        font-size: 10px


/*! --- LINKS -------------------------- */
.link
  transit()

  &:hover
    opacity: 0.6

.link-ui
  transit()

  &:hover
    color: colorPrimeLighten

    .icon
      fill colorPrimeLighten


.link-color-ui
  color: colorPrimeLighten
  transit()

  &:hover
    color: darken(colorPrimeLighten, 15%)


.backlink-ui
  display: inline-flex
  align-items: center
  font-size: 18px
  color: colorDark
  @media phone
    font-size: 14px

  .icon
    flex: none
    fill: colorDark
    size(30px,24px)
    margin-right: 14px
    transit()
    @media phone
      size(15px,12px)

  &--light
    color: colorLight

    .icon
      fill: colorLight


/*! --- PAGE --------------------------- */
.section-page
  padding: 40px 0 96px
  @media pad
    padding: 20px 0 48px


.title-page
  font-size: 60px
  font-family: nextart
  line-height: (70/60)
  text-transform: uppercase
  margin-bottom: 50px
  @media pad
    font-size: 45px
    margin-bottom: 40px
  @media phone
    font-size: 30px
    margin-bottom: 25px

  &--single
    margin-bottom: 0


.section
  padding: 100px 0
  @media tablet
    padding: 50px 0

  &-dark
    background-color: colorDark
    color: #fff

  &-light
    background-color: #fff
    color: colorDark

  &__footer
    text-align: center
    padding-top: 20px
    position: relative
    z-index: 2

    .call-button-group
      padding-top: 20px


.section-header
  margin-bottom: 55px
  @media tablet
    margin-bottom: 35px
  @media phone
    margin-bottom: 25px


.section-title
  text(40,50)
  font-family: nextart
  font-weight: bold
  @media tablet
    font-size: 30px
  @media pad
    font-size: 24px

  &:not(:last-child)
    margin-bottom: 25px
    @media phone
      margin-bottom: 15px

  strong
    font-size: 140px
    line-height: 1
    @media tablet
      font-size: 70px
    @media phone
      font-size: 35px


.section-subtitle
  font-size: 20px
  @media phone
    font-size: 18px

  &--half
    max-width: 50%
    @media pad
      max-width: 100%

  a
    font-family: futuraDemi
    font-weight: 500


/*! --- BUTTON ------------------------- */
.btn-border
  display: inline-flex
  width: 220px
  max-width: 100%
  height: 57px
  align-items: center
  justify-content: center
  text-align: center
  padding: 5px 20px 8px
  font-size: 20px
  font-family: futura
  font-weight: 500
  border: 1px solid #fff
  line-height: 1
  transit()
  @media pad
    width: auto
    font-size: 18px
    height: 47px

  .icon
    flex: none
    margin-left: 10px
    size(30px,30px)
    fill: colorLight
    transit()
    @media pad
      size(20px,20px)
      margin-left: 20px

  .icon-plus
    size(20px,20px)

  &:hover
    border-color: colorPrimeLighten
    color: colorPrimeLighten

    .icon
      fill: colorPrimeLighten

  &--dark
    border-color: colorDark

    .icon
      fill: colorDark

  &--block
    width: 100%

  &--inline
    width: auto

  &--wide
    width: 100%
    max-width: 300px
    border-color: #979797

  &--icon
    justify-content: space-between


.btn
  background-color: colorDark
  color: #fff
  display: inline-flex
  align-items: center
  justify-content: center
  font-family: futura
  font-weight: 500
  font-size: 20px
  padding: 5px 10px
  height: 57px
  @media phone
    height: 40px
    font-size: 18px
    padding: 5px 3em

  &:hover
    background-color: colorPrimeLighten

  &--300
    width: 100%
    max-width: 300px
    @media phone
      width: auto
      max-width: none

  &--block
    width: 100%

  &--form
    height: 66px
    @media phone
      height: 46px


/* --- FORM --------------------------- */
.input
  display: block
  width: 100%
  background-color: #fff
  padding: 0 20px
  height: 66px
  border: 1px solid colorDark
  font-size: 20px
  @media phone
    font-size: 16px
    height: 46px

  &::placeholder
    color: #a7a7a7

.fg
  position: relative

  &:not(:last-child)
    margin-bottom: 20px

  .error-message
    position: absolute
    bottom: 2px
    right: 5px

.error-message
  color: colorError
  font-size: 0.9em
  @media phone
    font-size: 0.8em


/*! --- SLIDER ------------------------- */
.sliderGallery-ui
  .swiper-container
    width: 100%

  .slider-controls-group
    margin-top: 30px
    @media phone
      margin-top: 15px

  .swiper-slide
    width: ((957 * 100%) / 1440)
    opacity: 0.5
    transit()
    padding: 0 21px
    @media pad
      padding: 0 12px

    &-active
      opacity: 1

    .img
      max-height: 100%
      margin: 0 auto

    &.is-cover .img
      object-fit: cover
      height: 100%


.slider-counter
  display: flex
  align-items: center
  font-size: 40px
  font-family: nextart
  font-weight: bold
  color: colorDark
  @media pad
    font-size: 30px
  @media phone
    font-size: 16px

  .current,
  .total
    display: inline-block

  .current
    margin-right: 8px

  .total
    margin-left: 8px


.slider-controls-group
  display: flex
  align-items: center
  justify-content: center

  .slider-counter
    margin: 0 20px


.slider-button
  .icon
    fill: colorDark
    size(40px,78px)
    @media pad
      size(20px,39px)


.sliderGallery-ui--light
  .slider-counter
    color: #fff

  .slider-button
    .icon
      fill: #fff

    &:hover .icon
      fill: colorPrimeLighten


/*! --- VIDEO ------------------------- */
.video-player-ui
  width: 100%
  max-width: 957px
  margin: 0 auto

  .v-vlite .v-bigPlayButton
    size(120px,120px)
    bg(center,contain)
    background-image: url('../icons/play.svg')
    @media phone
      size(60px,60px)

    &:hover
      opacity: 0.6

    .v-iconBigPlay
      svg
        display: none


/*! --- COVER PICTURE ----------------- */
.cover-picture
  max-height: 370px
  overflow: hidden
  background-color: colorDark
  @media pad
    max-height: 270px

  .img
    width: 100%
    max-height: 370px
    object-fit: cover
    @media pad
      max-height: 270px
    @media phone
      max-height: 170px


/* --- SOCIAL LIST -------------------- */
.socials-list
  display: flex
  align-items: center
  font-size: 18px
  font-family: futuraDemi
  font-weight: 500
  text-transform: uppercase

  li:not(:last-child)
    margin-right: 20px


/* --- ACCORDION ---------------------- */
.accordions
  &__item
    &:not(:last-child)
      margin-bottom: 46px
      @media pad
        margin-bottom: 30px
      @media phone
        margin-bottom: 20px

  &--tight &__item
    &:not(:last-child)
      margin-bottom: 30px
      @media tablet
        margin-bottom: 20px


.accordions-grid
  margin: 0 -20px

  &__item
    width: 50%
    @media pad
      width: 100%

    .accordion
      margin: 0 20px


.accordion
  border: 2px solid #414141

  &__header
    width: 100%
    min-height: 154px
    padding: 40px (40px + 78) 10px 200px
    position: relative
    cursor: pointer
    @media tablet
      min-height: auto
      padding-top: 16px
      padding-right: 20px + 40
      padding-left: 130px
      padding-bottom: 16px
    @media pad
      padding-right: 10px
      padding-left: 90px
    @media phone
      padding-right: 10px + 40
      padding-left: 40px

  &__number
    font-size: 140px
    font-family: nextart
    font-weight: bold
    color: rgba(#d5d5d5, 0.25)
    position: absolute
    left: 15px
    bottom: 0
    line-height: 0.75
    @media tablet
      font-size: 70px
    @media pad
      font-size: 50px
      left: 5px
      bottom: auto
      top: 50%
      transform: translateY(-50%)
    @media phone
      font-size: 20px

  &__name
    font-size: 60px
    text-transform: uppercase
    font-family: nextart
    font-weight: bold
    transit()
    @media tablet
      font-size: 40px
    @media pad
      font-size: 30px
    @media phone
      font-size: 20px

  &__arrow
    position: absolute
    top: 0
    right: 40px
    size(78px,100%)
    @media tablet
      size(39px,100%)
      right: 20px
    @media pad
      right: 10px
    @media phone
      size(40px,100%)

    .icon
      display: block
      size(100%,100%)
      fill: colorDark
      transit()

    &:hover .icon
      fill: colorPrimeLighten

  &__header:hover &
    &__name
      color: colorPrimeLighten
    &__arrow .icon
      fill: colorPrimeLighten

  &__header.is-active &__arrow
    transform: rotateX(180deg)

  &__body
    padding-top: 58px
    padding-bottom: 46px
    @media pad
      padding: 10px 0 20px

  &__text
    font-size: 30px
    padding-left: (100%/12) * 2
    padding-right: (100%/12) * 1
    @media tablet
      padding-left: (100%/12) * 1
      font-size: 20px
    @media pad
      padding-left: 40px
      padding-right: 40px
    @media phone
      padding-left: 20px
      padding-right: 20px
      font-size: 18px

    strong
      font-family: futuraDemi
      line-height: 1.4
      font-weight: 500

    a:hover
      color: colorPrimeLighten

    p:not(:last-child)
      margin-bottom: 1em

  &--light
    color: colorLight

  &--light &
    &__arrow
      .icon
        fill: colorLight

      &:hover .icon
        fill: colorPrimeLighten

  &--no-number &
    &__header
      padding: 5px (55px + 30) 5px 30px
      min-height: auto
      height: 86px
      display: flex
      align-items: center
      @media tablet
        padding: 10px (40px + 30) 10px 20px
      @media pad
        height: auto
      @media phone
        padding-right: (20px + 30)
        padding-left: 10px

    &__body
      padding-top: 16px
      padding-bottom: 30px
      @media phone
        padding-top: 10px
        padding-bottom: 20px

    &__name
      line-height: 1
      font-size: 26px
      font-family: futura
      font-weight: 500
      text-transform: none
      @media pad
        font-size: 20px
      @media phone
        line-height: 1.2

    &__arrow
      size(55px,100%)
      right: 20px
      @media tablet
        size(39px,100%)
        right: 20px
      @media pad
        right: 10px
      @media phone
        size(40px,100%)

    &__text
      font-size: 20px
      padding-left: (100%/12) * 1
      padding-right: (100%/12) * 1
      @media pad
        font-size: 18px
        padding-left: 40px
        padding-right: 40px
      @media phone
        padding-left: 20px
        padding-right: 20px




/* --- ACCORDION CONTENT -------------- */
.accordion-article-list
  padding-left: (100%/12) * 2
  padding-right: (100%/12) * 1
  @media tablet
    padding-left: (100%/12) * 1
  @media pad
    padding-left: 40px
    padding-right: 40px
  @media phone
    padding-left: 20px
    padding-right: 20px

  &__item
    &:not(:last-child)
      margin-bottom: 24px

  &__title
    display: inline-block
    text-transform: uppercase
    font-size: 30px
    font-family: futuraDemi
    margin-bottom: 2px
    @media pad
      font-size: 20px
    @media phone
      font-size: 18px

  &__source
    text-transform: uppercase
    font-size: 20px
    font-family: futura
    font-weight: 500
    color: #b7b7b7
    @media pad
      font-size: 16px
    @media phone
      font-size: 14px


.accordion-troupe
  &__item
    padding-left: (100%/12) * 2
    padding-right: (100%/12) * 1
    position: relative
    overflow: hidden
    @media tablet
      padding-left: (100%/12) * 1
    @media pad
      padding-left: 40px
      padding-right: 40px
    @media phone
      padding-left: 20px
      padding-right: 20px

    &:not(:last-child)
      border-bottom: 1px solid #b7b7b7
      padding-bottom: 35px
      margin-bottom: 35px
      @media pad
        padding-bottom: 20px
        margin-bottom: 20px

  &__bg
    font-size: 120px
    font-family: nextart
    color: #f4f4f4
    font-weight: bold
    white-space: nowrap
    position: absolute
    left: -10%
    top: 8px
    @media pad
      font-size: 90px
      left: -5%
    @media phone
      font-size: 60px

  &__text
    font-family: futuraDemi
    font-weight: 500
    font-size: 30px
    text-transform: uppercase
    position: relative
    @media pad
      font-size: 20px

    p:not(:last-child)
      margin-bottom: 0.3em

    em, i
      text-transform: none
      font-family: futuraBook
      font-weight: normal
      font-style: normal
      font-size: 18px
      @media pad
        font-size: 14px


.accordion-event-buy
  overflow: hidden

  &__item
    padding-left: (100%/12) * 2
    padding-right: (100%/12) * 1
    align-items: center
    @media tablet
      padding-left: (100%/12) * 1
    @media pad
      padding-left: 40px
      padding-right: 40px
      align-items: flex-end
    @media phone
      padding-left: 20px
      padding-right: 20px

    &:not(:last-child)
      padding-bottom: 36px
      margin-bottom: 36px
      border-bottom: 1px solid #b7b7b7
      @media pad
        padding-bottom: 26px
        margin-bottom: 26px

  &__place
    font-size: 20px
    @media phone
      font-size: 16px
      padding-top: 10px

  &__button
    text-align: center
    @media pad
      text-align: left
      padding-top: 20px


.event-date
  display: flex
  align-items: flex-end
  text-transform: uppercase

  &__number
    font-family: nextart
    font-size: 80px
    font-weight: bold
    line-height: 0.88
    @media phone
      font-size: 50px

  &__text
    text-transform: uppercase
    padding-left: 14px

  &__month
    font-size: 20px
    font-family: nextart
    font-weight: bold
    @media phone
      font-size: 14px

  &__day
    color: #a6a6a6
    font-size: 20px
    @media phone
      font-size: 14px

  &__label
    display: inline-flex
    align-items: center
    align-self: center
    font-size: 20px
    font-family: nextart
    font-weight: bold
    color colorPrimeLighten
    border: 1px solid colorPrimeLighten
    padding: 0 8px
    height: 31px
    margin-left: 30px
    @media pad
      font-size: 16px
    @media phone
      font-size: 14px

  &--light &__number
    color: colorLight

  &--small &
    &__number
      font-size: 60px
      @media phone
        font-size: 50px

  &.is-soon &__number
    font-size: 40px
    @media pad
      font-size: 30px


.accordion-side-by-side
  padding: 0 40px
  @media phone
    padding: 0 20px

  .side-by-side__item
    @media pad
      flex-direction: column-reverse

    &:not(:last-child)
      margin-bottom: 100px
      @media pad
        margin-bottom: 60px

    &:nth-child(2n)
      @media pad
        flex-direction: column-reverse

      &:last-child
        flex-direction: row

  &.side-by-side--rows .side-by-side__item:nth-child(2n)
    @media pad
      flex-direction: column-reverse


/* --- PICTURE WRAPPER ANIMATION ------ */
.picture-overlay
  position: relative

  &__body
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: colorPrime
    overflow: hidden

  &__item
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: (100%/4)
    background-color: colorPrimeLighten
    transform: translateX(-100%)

    &:nth-child(2)
      top: (100%/4)

    &:nth-child(3)
      top: (100%/4) * 2

    &:nth-child(4)
      top: (100%/4) * 3
