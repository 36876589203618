/*!
 * --- SECTION TEAM -----------------------------
 */
.section-team
  padding-bottom: 0

  &__items
    padding: 10px 0 60px
    background-color: #fff
    color: colorDark
    @media phone
      padding: 0 0 20px


.team-list
  &__item
    padding: 50px 0 0
    @media phone
      padding: 25px 0 0

    &:not(:last-child)
      border-bottom: 1px solid #b7b7b7

  // &__letter-wrapper
  //   position: relative

  &__letter
    font-size: 40px
    line-height: 1
    font-family: nextart
    font-weight: bold
    text-transform: uppercase
    color: #b7b7b7
    transit()
    @media pad
      padding-bottom: 20px

    &.is-active
      position: fixed
      top: 10px
      z-index: 2
      @media pad
        position: static

    &.is-bottom
      position: absolute
      bottom: 40px
      top: auto
      z-index: 2
      @media pad
        position: static

  &__persons-item
    padding-bottom: 40px


.team-person
  display: block

  &__picture
    height: 307px
    margin-bottom: 14px
    @media phone
      height: 207px

    .img
      width: 100%
      height: 100%
      object-fit: cover

  &__name
    text-transform: uppercase
    font-family: futuraDemi
    font-size: 30px
    font-weight: 500
    line-height: (34/30)
    transit()
    @media phone
      font-size: 18px

  &__position
    margin-top: 4px
    font-size: 20px
    color: #b7b7b7
    @media phone
      font-size: 14px

  &[href]:hover &__name
    color: colorPrimeLighten
