/*!
 * --- SECTION ABOUT ----------------------------
 */
.section-about
  padding-top: 0

.about-hero
  position: relative

  &__body
    height: 100vh
    position: relative
    overflow: hidden
    max-height: 1080px
    margin-bottom: 100px
    @media tablet
      max-height: none
      height: auto
      margin-bottom: 0

    &:after
      pseudo()
      width: 100%
      height: 100%
      left: 0
      top: 0
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 4%, #000000 84%)

  &__picture
    display: none
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    overflow: hidden
    @media tablet
      overflow: auto
      position: relative

    &:before
      pseudo()
      width: 100%
      height: 100%
      left: 0
      top: 0
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 4%, #000000 84%)

    .img
      max-width: none
      width: 100%

  &__video
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    min-width: 100%
    min-height: 100%
    width: auto
    @media tablet
      height: auto
      width: 100%
      position: relative
      top: auto
      left: auto
      transform: none

  &__center
    position: absolute
    top: 50%
    left: 50%
    width: 1px
    height: 1px
    // background-color: blue
    // z-index: 10

  &__title
    text-transform: uppercase
    font-family: nextart
    font-weight: bold
    color: colorPrimeLighten

    &-value
      display: inline-block
      font-size: 40px
      position: relative
      transform-origin: 0 50%
      @media pad
        font-size: 30px
      @media phone
        font-size: 18px

  // .center-pos
  //   text-transform: uppercase
  //   font-family: nextart
  //   font-weight: bold
  //   color: colorPrimeLighten
  //   font-size: 180px
  //   white-space: nowrap
  //   position: absolute
  //   top: 50%
  //   left: 50%
  //   transform: translate(-50%,-50%)


.about-content
  // padding-top: 100px
  padding-top: 40px
  padding-bottom: 120px
  @media pad
    padding-top: 20px
    padding-bottom: 50px

  &__header
    text-transform: uppercase
    font-family: nextart
    font-weight: bold
    margin-bottom: 62px
    @media phone
      margin-bottom: 30px

  &__pretitle
    color: colorPrimeLighten
    font-size: 40px
    margin-bottom: 40px
    @media pad
      font-size: 30px
      margin-bottom: 20px
    @media phone
      font-size: 18px

  &__title
    text(60,70)
    @media pad
      font-size: 40px
    @media phone
      font-size: 20px

  &__letters
    &.animated-lines
      margin-top: 0
      padding: 70px 0 72px
      font-size: 120px
      @media tablet
        font-size: 70px
        text-align: center
      @media pad
        font-size: 50px
      @media phone
        padding: 30px 0
        font-size: 24px

  &__txt
    &-picture
      max-width: ((835 * 100%) / 1170)
      @media phone
        max-width: 100%

      .img
        width: 100%

    &-content
      font-size: 40px
      max-width: (100% / 12) * 8
      @media tablet
        font-size: 30px
        max-width: 100%
      @media phone
        font-size: 18px

      p:not(:last-child)
        margin-bottom: 1.5em

    &-picture + &-content
      margin-left: (100% / 12) * 3
      margin-top: -1.6em
      max-width: none
      @media phone
        margin-left: 0


.accordion-logo-list
  padding: 0 40px
  @media phone
    padding: 0 20px

  &__item
    display: flex
    align-items: center
    font-size: 30px
    padding: 30px 0
    @media pad
      font-size: 20px
      padding: 16px 0

    &:not(:last-child)
      border-bottom: 2px solid #414141

  &__number
    width: 100%
    max-width: 10%

  &__body
    display: flex
    align-items: center
    width: 100%
    max-width: 90%

  &__picture
    width: 100%
    max-width: 21%
    min-width: 100px
    @media phone
      min-width: 60px

    picture
      display: block

    .img
      width: auto
      max-height: 96px
      @media pad
        max-height: 56px
      @media phone
        max-height: 42px


.about-history-content
  @media pad
    padding-top: 20px

  &__title
    text-transform: uppercase
    font-family: nextart
    font-weight: bold
    font-size: 100px
    margin-bottom: 20px
    @media pad
      font-size: 24px

  &__txt
    font-size: 20px
    @media pad
      font-size: 18px

    p:not(:last-child)
      margin-bottom: 0.7em

  &__logo
    padding-top: 40px
    @media pad
      padding-top: 20px

    .img
      max-width: 300px
      max-height: 110px
      @media pad
        max-width: 150px
        max-height: 50px

  &__awards
    font-size: 30px
    @media pad
      font-size: 18px

    &[class]
      padding-top: 30px
      @media phone
        padding-top: 20px

    li
      display: flex
      align-items: center
      &:not(:last-child)
        margin-bottom: 0.5em

      .icon
        fill: #f5a1a1
        size(28px,30px)
        margin-right: 14px
        @media phone
          size(18px,20px)


.accordion-management
  padding: 0 40px
  @media phone
    padding: 0 20px

  &__item
    display: flex
    @media phone
      flex-direction: column

    &:not(:last-child)
      margin-bottom: 58px
      @media phone
        margin-bottom: 40px

  &__picture
    flex: none
    width: 100%
    // max-width: 520px
    max-width: ((520 * 100%) / 1076)
    position: relative
    overflow: hidden
    @media pad
      max-width: 60%
    @media phone
      max-width: 100%
      height: 200px

    &:before
      pseudo()
      width: 100%
      height: 100%
      top: 0
      left: 0
      background-image: linear-gradient(to left, #000000, rgba(0, 0, 0, 0) 55%)
      @media phone
        background-image: linear-gradient(to top, #000000, rgba(0, 0, 0, 0) 55%)

    .img
      width: 100%
      height: 100%
      object-fit: cover

  &__body
    position: relative
    left: -70px
    padding: 60px 0
    @media pad
      left: -35px
    @media phone
      left: 0
      margin-top: -50px
      padding-top: 20px
      padding-bottom: 0

  &__name
    font-family: nextart
    font-weight: bold
    text(50,60)
    text-transform: uppercase
    margin-bottom: 16px
    @media pad
      font-size: 30px
    @media phone
      font-size: 24px
      margin-bottom: 0

  &__position
    font-size: 30px
    @media pad
      font-size: 20px

  &__bottom
    padding-top: 40px
    @media phone
      padding-top: 20px
