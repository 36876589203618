/*!
 * --- SECTION CONTACTS -------------------------
 */
.blockContacts
  &__item
    margin-top: 38px
    @media phone
      margin-top: 28px

    &:not(:last-child)
      padding-bottom: 30px
      border-bottom: 1px solid rgba(#fff, 0.1)

  &__line

    &:not(:last-child)
      margin-bottom: 28px

  &__label
    font-size: 20px
    @media pad
      font-size: 18px
    @media phone
      margin-bottom: 10px

  &__name
    font-size: 20px
    margin-bottom: 10px
    @media pad
      font-size: 16px

  &__value
    font-size: 30px
    text-transform: uppercase
    font-family: futuraDemi
    font-weight: 500
    @media pad
      font-size: 20px

    p:not(:last-child)
      margin-bottom: 10px
