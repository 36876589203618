/*!
 * --- SECTION EVENT LIST -----------------------
 */
.section-event-list
  &__item
    &:not(:last-child)
      margin-bottom: 100px
      @media pad
        margin-bottom: 60px


.event-list-buy
  &__item
    display: flex
    @media phone
      flex-direction: column

    &:not(:last-child)
      margin-bottom: 40px
      @media phone
        margin-bottom: 60px

  &__picture
    max-width: 460px
    position: relative
    @media phone
      max-width: 100%
      height: 200px

    &:before
      pseudo()
      right: 0
      top: 0
      height: 100%
      width: (100%/3)
      background: linear-gradient(90deg, rgba(#fff,0) 0%, colorDark 100%)
      @media phone
        left: 0
        bottom: 0
        height: (100%/3)
        width: 100%
        top: auto
        background: linear-gradient(180deg, rgba(#fff,0) 0%, colorDark 100%)

    .img
      width: 100%
      height: 100%
      object-fit: cover

  &__body
    flex-grow: 1
    position: relative
    left: -60px
    padding-top: 40px
    padding-bottom: 20px
    @media phone
      left: auto
      margin-top: -50px
      padding-top: 20px
      padding-bottom: 0

  &__header
    margin-bottom: 15px
    @media phone
      margin-left: 10px

  &__title
    text-transform: uppercase
    color: colorLight
    font-size: 40px
    font-family: nextart
    font-weight: bold
    margin-bottom: 10px
    @media pad
      font-size: 30px
    @media phone
      font-size: 20px

  &__place
    font-size: 20px
    line-height: (28/20)
    color: #a6a6a6
    margin-bottom: 30px
    @media phone
      font-size: 16px
      margin-bottom: 20px

  &__buttons
    display: flex
    align-items: center

  &__button
    width: 100%
    max-width: 272px

    &:not(:last-child)
      margin-right: 30px
      @media phone
        margin-right: 15px


.event-list-row
  &__item
    &:not(:last-child)
      margin-bottom: 40px
      @media pad
        margin-bottom: 20px

  &__box
    border: 1px solid #414141
    padding: 40px
    display: flex
    align-items: center
    justify-content: space-between
    transit()
    @media pad
      padding: 20px
    @media phone
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start

    &[href]:hover
      background-color: colorLight

  &__title
    text-transform: uppercase
    font-family: nextart
    font-weight: bold
    font-size: 40px
    margin-bottom: 10px
    @media pad
      font-size: 30px
    @media phone
      font-size: 20px

  &__text
    color: #a6a6a6
    font-size: 20px
    line-height: (28/20)
    @media pad
      font-size: 16px

  &__link
    @media phone
      margin-top: 20px
      align-self: flex-end

    .icon
      size(158px,67px)
      fill: colorLight
      transit()
      @media pad
        size(80px,34px)
      @media phone
        size(40px,17px)

  &__item:hover &
    &__title
      color: colorPrime

    &__text
      color: #000

    &__link
      .icon
        fill: colorDark

      &:hover .icon
        fill: colorPrime
