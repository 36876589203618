/*!
 * --- SECTION 404 ------------------------------
 */
.section-404
  height: 100%
  display: flex
  align-items: center
  padding: 40px 0
  @media phone
    padding: 20px 0

  &__title
    font-family: nextart
    font-weight: bold
    text-transform: uppercase
    font-size: 60px
    margin-bottom: 49px
    @media tablet
      font-size: 40px
    @media phone
      font-size: 24px

    h1
      font-size: (140em/60)

    p
      margin-top: -10px
      @media phone
        margin-top: 0

  &__bottom
    font-size: 18px
